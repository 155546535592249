import React from 'react';
import { useLanguage } from '../../context/LanguageContext';

function SimonAdvantages() {
  const { dictionary } = useLanguage();

  return (
    <section className="mainSection">
      <h2>{dictionary.columbo.advantagesTitle}</h2>
      <div className="advantagesWrapper">
        <div className="advantages">
          <h3>{dictionary.simon.advantages.onesubtitle}</h3>
          <ul>
            <li>{dictionary.simon.advantages.oneli1}</li>
            <li>{dictionary.simon.advantages.oneli2}</li>
            <li>{dictionary.simon.advantages.oneli3}</li>
          </ul>
        </div>
        <div className="advantages">
          <h3>{dictionary.simon.advantages.twosubtitle}</h3>
          <ul>
            <li>{dictionary.simon.advantages.twoli1}</li>
            <li>{dictionary.simon.advantages.twoli2}</li>
          </ul>
        </div>
        <div className="advantages">
          <h3>{dictionary.simon.advantages.threesubtitle}</h3>
          <ul>
            <li>{dictionary.simon.advantages.threeli1}</li>
            <li>{dictionary.simon.advantages.threeli2}</li>
          </ul>
        </div>
        <div className="advantages">
          <h3>{dictionary.simon.advantages.foursubtitle}</h3>
          <ul>
            <li>{dictionary.simon.advantages.fourli1}</li>
            <li>{dictionary.simon.advantages.fourli2}</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default SimonAdvantages;
