import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import LandingPage from './pages/LandingPage';
import Miguel from './pages/Miguel';
import Columbo from './pages/Columbo';
import Miluska from './pages/Miluska';
import Edita from './pages/Edita';
import References from './pages/References';
import Contact from './pages/Contact';
import Services from './pages/Services';
import AboutUs from './pages/AboutUs';
import Blog from './pages/Blog';
import Cookies from './pages/Cookies';
import Simon from './pages/Simon';
import Tchaiwan from './pages/Tchaiwan';
import Jacob from './pages/Jacob';
import Ella from './pages/Ella';
import JoinBeta from './pages/JoinBeta';
import { useLanguage } from '../src/context/LanguageContext';
import MiguelPresta from './pages/MiguelPresta';
import MiguelWoo from './pages/MiguelWoo';
import SimonForm from './pages/SimonForm';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const { userLangChange } = useLanguage();
  const navigate = useNavigate();

  const handleLanguageChange = (selectedLang) => {
    // Přesměrování na aktuální stránku s novým jazykem
    const { pathname } = window.location;
    const slug = pathname.substring(pathname.lastIndexOf('/') + 1);
    navigate(`/${selectedLang}/${slug}`);
    userLangChange(selectedLang);
  };

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={<LandingPage onLanguageChange={handleLanguageChange} />}
        />
        <Route
          path="/:lang"
          element={<LandingPage onLanguageChange={handleLanguageChange} />}
        />
        <Route
          path="/:lang/miguel"
          element={<Miguel onLanguageChange={handleLanguageChange} />}
        />
        <Route
          path="/:lang/columbo"
          element={<Columbo onLanguageChange={handleLanguageChange} />}
        />
        <Route
          path="/:lang/miluska"
          element={<Miluska onLanguageChange={handleLanguageChange} />}
        />
        <Route
          path="/:lang/edita"
          element={<Edita onLanguageChange={handleLanguageChange} />}
        />
        <Route
          path="/:lang/simon"
          element={<Simon onLanguageChange={handleLanguageChange} />}
        />
        <Route
          path="/:lang/services"
          element={<Services onLanguageChange={handleLanguageChange} />}
        />
        <Route
          path="/:lang/blog"
          element={<Blog onLanguageChange={handleLanguageChange} />}
        />
        <Route
          path="/:lang/reference"
          element={<References onLanguageChange={handleLanguageChange} />}
        />
        <Route
          path="/:lang/about"
          element={<AboutUs onLanguageChange={handleLanguageChange} />}
        />
        <Route
          path="/:lang/contact"
          element={<Contact onLanguageChange={handleLanguageChange} />}
        />
        <Route
          path="/:lang/cookies"
          element={<Cookies onLanguageChange={handleLanguageChange} />}
        />
        <Route
          path="/:lang/news"
          element={<Tchaiwan onLanguageChange={handleLanguageChange} />}
        />
        <Route
          path="/:lang/jacob"
          element={<Jacob onLanguageChange={handleLanguageChange} />}
        />
        <Route
          path="/:lang/ella"
          element={<Ella onLanguageChange={handleLanguageChange} />}
        />
        <Route path="/miguel_prestashop" element={<MiguelPresta />} />
        <Route path="/miguel_woocommerce" element={<MiguelWoo />} />
        <Route path="/simonform" element={<SimonForm />} />
        <Route path="/en/join-beta" element={<JoinBeta />} />
      </Routes>
    </>
  );
}

export default App;
