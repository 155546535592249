import React, { useState } from 'react';
import arrow from '../../assets/arrow.svg';

function FAQitem({ faqQuestion, faqAnswer, faqImg }) {
  const [faqOpen, setFaqOpen] = useState(false);

  return (
    <div className="faqItem">
      <div className="faqItemHeader" onClick={() => setFaqOpen(!faqOpen)}>
        <h5>{faqQuestion}</h5>
        <img
          src={arrow}
          alt=""
          style={{ transform: faqOpen && 'rotate(180deg)' }}
        />
      </div>
      {faqOpen && (
        <div>
          <p>{faqAnswer}</p>
          {faqImg && <img className="faqItemImage"src={faqImg} alt="" />}{' '}
        </div>
      )}
    </div>
  );
}

export default FAQitem;
