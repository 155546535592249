import React from 'react';
import { useLanguage } from '../context/LanguageContext';
import styled from 'styled-components';

const StyledLanguageSwitch = styled.button`
  color: #173442;
  font-size: 16px;
  font-weight: 600;
  line-height: 60px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  @media (min-width: 977px) {
    line-height: 24px;
    padding: 0 24px;
    font-size: 19px;
  }
`;

function LanguageSwitch({ onLanguageSwitch }) {
    const { userLang, userLangChange } = useLanguage();

    const otherLang = userLang === 'en' ? 'cs' : 'en';
    const handleLanguageChange = () => userLangChange(otherLang);

    const handleSwitchLanguage = () => {
        handleLanguageChange();
        onLanguageSwitch(otherLang);
    };

    return (
        <StyledLanguageSwitch
            className="languageSwitch"
            onClick={handleSwitchLanguage}
        >
            {otherLang.toUpperCase()}
        </StyledLanguageSwitch>
    );
}

export default LanguageSwitch;
