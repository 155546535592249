import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import phone from '../assets/footerPhone.svg';
import email from '../assets/email.svg';
import czechinvest from '../assets/czechinvest.png';
import { generatePath } from 'react-router-dom';
import '../styles/footer.scss';

function Footer({ czechInvestVisible }) {
  const { dictionary } = useLanguage();
  const { userLang } = useLanguage();


  return (
    <footer className="footer">
      <div className="footerColumn">
        <h4>{dictionary.footer.address}</h4>
        <span>Neklanova 150/38</span>
        <span>Vyšehrad</span>
        <span>128 00 Praha</span>
      </div>
      <div className="footerColumn">
        <h4>{dictionary.footer.products}</h4>
        <Link to={generatePath('/:lang/miguel', { lang: userLang })}>
          Miguel
        </Link>
        <Link to={generatePath('/:lang/columbo', { lang: userLang })}>
          Columbo
        </Link>
        <Link to={generatePath('/:lang/simon', { lang: userLang })}>Simon</Link>
        <Link to={generatePath('/:lang/jacob', { lang: userLang })}>Jacob</Link>
        <Link to={generatePath('/:lang/edita', { lang: userLang })}>Edita</Link>
        <Link to={generatePath('/:lang/miluska', { lang: userLang })}>
          Miluška
        </Link>
      </div>
      <div className="footerColumn">
        <h4>{dictionary.footer.information}</h4>
        <Link to={generatePath('/:lang/news', { lang: userLang })}>
          {dictionary.footer.news}
        </Link>
        <a
          href="https://docs.miguel.servantes.cz/"
          target="_blank"
          rel="noreferrer"
        >
          {dictionary.navigation.docu}
        </a>
        <Link to={generatePath('/:lang/reference', { lang: userLang })}>
          {dictionary.navigation.references}
        </Link>
        <Link to={generatePath('/:lang/about', { lang: userLang })}>
          {dictionary.navigation.about}
        </Link>
        <Link to={generatePath('/:lang/contact', { lang: userLang })}>
          {dictionary.navigation.contact}
        </Link>
      </div>
      <div className="footerColumn">
        <h4>{dictionary.footer.contact}</h4>
        <div className="footerContact">
          <img src={phone} alt="ikona telefonu" />
          <span>{dictionary.footer.contactPhone}</span>
        </div>
        <div className="footerContact">
          <img src={email} alt="ikona emailu" />
          <a href="mailto:info@servantes.cz">
            {dictionary.footer.contactEmail}
          </a>
        </div>
      </div>
      {!czechInvestVisible && (
        <div className="czechInvest">
          <p>{dictionary.footer.czechInvest}</p>
          <img className="footerLogo" src={czechinvest} alt="" />
        </div>
      )}
    </footer>
  );
}

export default Footer;
