import React from 'react';
import Header from '../components/headerComponents/Header';
import Footer from '../components/Footer';
import { useLanguage } from '../context/LanguageContext';
import miluskaLogo from '../assets/miluska_logo.png';
import ProductHero from '../components/productPageComponents/ProductHero';
import miluska from '../assets/miluska.svg';
import FAQ from '../components/productPageComponents/FAQ';
import milCheck from '../assets/check_miluska.svg';
import ProductExplanation from '../components/productPageComponents/ProductExplanation';
import milArrow from '../assets/arrow_miluska.svg';
import ProductCustomers from '../components/productPageComponents/ProductCustomers';
import melvil from '../assets/publishers/melvil.png';
import mediaplex from '../assets/publishers/mediaplex.png';
import epocha from '../assets/publishers/epocha.png';
import MiluskaPrice from '../components/miluskaComponents/MiluskaPrice';
import '../styles/miluska.scss';
import '../styles/products.scss';

function Miluska({ onLanguageChange }) {
  const { dictionary } = useLanguage();

  return (
    <>
      <Header
        claimClass="claimHidden"
        logoSrc={miluskaLogo}
        logoClass="productLogo"
        onLanguageChange={onLanguageChange}
      />
      <ProductHero
        productName={dictionary.landingPage.miluska.productName}
        productTitle={dictionary.miluska.title}
        productSubtitle={dictionary.miluska.subtitle1}
        sellingPoints={[
          dictionary.miluska.sellingPoints.li1,
          dictionary.miluska.sellingPoints.li2,
          dictionary.miluska.sellingPoints.li3,
        ]}
        productImage={miluska}
        productCheck={milCheck}
      />
      <ProductExplanation
        explanation1={dictionary.miluska.explanation.p1}
        explanation2={dictionary.miluska.explanation.p2}
        explanation3={dictionary.miluska.explanation.p3}
        arrow={milArrow}
      />
      <ProductCustomers customers={[melvil, mediaplex, epocha]} />
      <MiluskaPrice />
      <FAQ
        faqQuestion1={dictionary.miluska.faq.question1}
        faqAnswer1={dictionary.miluska.faq.answer1}
        faqQuestion2={dictionary.miluska.faq.question2}
        faqAnswer2={dictionary.miluska.faq.answer2}
        faqQuestion3={dictionary.miluska.faq.question3}
        faqAnswer3={dictionary.miluska.faq.answer3}
        faqQuestion4={dictionary.miluska.faq.question4}
        faqAnswer4={dictionary.miluska.faq.answer4}
      />
      <Footer />
    </>
  );
}

export default Miluska;
