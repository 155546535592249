import React, { useState, useRef } from 'react';
import { useOnClickOutside } from '../../utils/hooks';
import Navigation from './Navigation';
import topLine from '../../assets/topLine.svg';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import Hamburger from './Hamburger';
import '../../styles/header.scss';

function Header({
  claimClass,
  logoSrc,
  logoClass,
  onLanguageChange,
  miguelPrestaHeader,
  miguelPresta,
}) {
  const { dictionary } = useLanguage();
  const [menuOpen, setMenuOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setMenuOpen(false));

  return (
    <header className="header">
      <div ref={node}>
        <Hamburger menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <Navigation
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          servantesLogoVisibility="logoVisible"
          onLanguageChange={onLanguageChange}
          miguelPresta={miguelPresta}
        />
      </div>
      <div className="logo">
        {!miguelPresta && (
          <div
            className="topLine"
            style={{
              backgroundImage: `url(${topLine})`,
            }}
          ></div>
        )}
        {miguelPrestaHeader && (
          <div className="logoWrapper mp-logoWrapper">
            <img src={logoSrc} alt="" className="mp-header-logo" />
          </div>
        )}
        {!miguelPresta && (
          <Link to="/" className="logoWrapper">
            <img src={logoSrc} alt="" className={logoClass} />
          </Link>
        )}
        <p
          className={
            claimClass === 'claimVisible' ? 'logoClaim' : 'claimHidden'
          }
        >
          {dictionary.landingPage.logoClaim}
        </p>
      </div>
      {menuOpen && <div className="cover"></div>}
    </header>
  );
}

export default Header;
