import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import columboPreview from '../../assets/columboPreview.jpg';


function ColumboControlls() {
  const { dictionary } = useLanguage();

  return (
    <section className="mainSection">
      <h2> {dictionary.columbo.controllsTitle}</h2>
      <img className="columboPreview" src={columboPreview} alt="" />
    </section>
  );
}

export default ColumboControlls;
