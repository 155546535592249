import React from 'react';
import miguelPrestaScreenshot from '../../assets/melvilScreenshot_presta.webp';

function MiguelPrestaCaseStudy() {
  return (
    <section className="mainSection">
      <h2>Publisher: "Our e-book turnover grew by 45%"</h2>
      <div className="miguelQuote mp-caseStudy">
        <img className="mp-melvilGraph" src={miguelPrestaScreenshot} alt="" />
        <blockquote>
          <p>
            “Our e-shop with Miguel is the main way we sell e-books. In the
            last year, it made up around 45% of all e-book sales. This is much
            higher than what we get from other sales channels.
          </p>
          <p>
            Our e-book sales have gone up a lot, and it hasn't affected sales
            in other e-shops.”
          </p>

          <cite>
            Tomas Baranek, co-owner of{' '}
            <a href="https://melvil.cz" target="_blank" rel="noreferrer">
              Jan Melvil Publishing
            </a>{' '}
          </cite>
        </blockquote>
      </div>
    </section>
  );
}

export default MiguelPrestaCaseStudy;
