import React, { useState } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

function ColumboForm() {
  const { dictionary } = useLanguage();
  const [checkUrl, setCheckUrl] = useState('');
  const [email, setEmail] = useState('');

  const toastOptions = {
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    position: 'bottom-center',
    draggable: true,
    progress: undefined,
  };

  const handleSubmit = (e) => {
    const url = '/main/columboform';
    const data = { url: checkUrl, email: email };
    e.preventDefault();
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => {
      console.log(response.status);
      if (response.status === 200) {
        toast.success('Formulář byl úspěšně odeslán.', toastOptions);
      } else if (response.status === 400) {
        toast.error(
          'Zkontrolujte, jestli máte správně vyplněné všechny informace.',
          toastOptions,
        );
      } else if (response.status === 429) {
        toast.error(
          'Překročen povolený počet requestů (vázáno na IP adresu)',
          toastOptions,
        );
      } else if (response.status > 500) {
        toast.error('Chyba serveru - zkuste to prosím později', toastOptions);
      } else toast.warning('Chyba v kódu', toastOptions);
      document.getElementById('form').reset();
    });
  };

  return (
    <section className="mainSection">
      <h2>{dictionary.columbo.formTitle}</h2>
      <div className="columboForm">
        <p>{dictionary.columbo.formDesc}</p>
        <form onSubmit={handleSubmit} id="form">
          <label htmlFor="url">{dictionary.columbo.formInput1}</label>
          <br />
          <input
            type="url"
            name="url"
            id="url"
            value={checkUrl}
            onChange={(e) => setCheckUrl(e.target.value)}
          />
          <br />
          <label htmlFor="email">{dictionary.columbo.formInput2}</label>
          <br />
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <button className="button" type="submit">
            {dictionary.columbo.formBtn}
          </button>
        </form>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}

export default ColumboForm;
