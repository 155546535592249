import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import TeamMemberMain from './TeamMemberMain';
import placeholder from '../../assets/placeholderMale.jpeg';
import TeamMemberOthers from './TeamMemberOthers';
import bara from '../../assets/team/bara.webp';
import tomas from '../../assets/team/tomáš.webp';
import vit from '../../assets/team/vít.webp';
import vasek from '../../assets/team/vasek.webp';
import gabina from '../../assets/team/gabina.webp';
import roman from '../../assets/team/roman.webp';
import zuzka from '../../assets/team/zuzka.webp';
import lukas from '../../assets/team/lukas.webp';
import jakub from '../../assets/team/jacob.webp';
import petr from '../../assets/team/petr.webp';
import scott from '../../assets/team/scott.webp';
import '../../styles/pages.scss';

function Team() {
  const { dictionary, userLang } = useLanguage();

  return (
    <section className="mainSection">
      <h2>{dictionary.aboutUs.subtitle}</h2>
      <div className="teamMain">
        <TeamMemberMain
          personImg={tomas}
          personName="Tomáš Baránek"
          personPosition={dictionary.aboutUs.tomas}
          personInfo={dictionary.aboutUs.tomasInfo}
        />
        <TeamMemberMain
          personImg={vit}
          personName="Vít Šebor"
          personPosition={dictionary.aboutUs.vit}
          personInfo={dictionary.aboutUs.vitInfo}
        />
        <TeamMemberMain
          personImg={vasek}
          personName="Václav Soukup"
          personPosition={dictionary.aboutUs.vaclav}
          personInfo={dictionary.aboutUs.vaclavInfo}
        />
        <TeamMemberMain
          personImg={jakub}
          personName="Jakub Šimůnek"
          personPosition={dictionary.aboutUs.kuba}
          personInfo={dictionary.aboutUs.kubaInfo}
        />
        {userLang === 'en' && (
          <TeamMemberMain
            personImg={scott}
            personName="Scott Hudson"
            personPosition={dictionary.aboutUs.scott}
            personInfo={dictionary.aboutUs.scottInfo}
          />
        )}
      </div>
      <div className="teamOthers">
        {/*   <TeamMemberOthers

          personImg={roman}
          personName="Roman Kříž"
          personPosition={dictionary.aboutUs.roman}
          personDetail={dictionary.aboutUs.romanDetails}
        /> */}
        <TeamMemberOthers
          personImg={petr}
          personName="Petr Hrachovec"
          personPosition={dictionary.aboutUs.petr}
          personDetail={dictionary.aboutUs.petrDetails}
        />

        {/* <TeamMemberOthers
          personImg={lukas}
          personName="Lukáš Brnka"
          personPosition={dictionary.aboutUs.lukas}
          personDetail={dictionary.aboutUs.lukasDetails}
        /> */}

        <TeamMemberOthers
          personImg={zuzka}
          personName="Zuzana Košek Strachotová"
          personPosition={dictionary.aboutUs.zuzka}
        />

        <TeamMemberOthers
          personImg={gabina}
          personName="Gabriela Otteová"
          personPosition={dictionary.aboutUs.gabriela}
          personDetail={dictionary.aboutUs.gabrielaDetails}
        />
  
        {userLang === 'cs' && (
          <TeamMemberOthers
            personImg={scott}
            personName="Scott Hudson"
            personPosition={dictionary.aboutUs.scott}
          />
        )}

        {/* <TeamMemberOthers
          personImg={placeholder}
          personName="Jan Volný"
          personPosition={dictionary.aboutUs.jan}
        /> */}
        <TeamMemberOthers
          personImg={placeholder}
          personName="Peter Ďurik"
          personPosition={dictionary.aboutUs.peter}
        />
        <TeamMemberOthers
          personImg={bara}
          personName="Bára Marešová"
          personPosition={dictionary.aboutUs.bara}
        />
      </div>
    </section>
  );
}

export default Team;
