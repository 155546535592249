import React from 'react';
import Header from '../components/headerComponents/Header';
import Footer from '../components/Footer';
import { useLanguage } from '../context/LanguageContext';
import servantesLogo from '../assets/logo.png';
import contact from '../assets/contact_img.jpg';
import '../styles/pages.scss';

function Contact({ onLanguageChange }) {
  const { dictionary, userLang } = useLanguage();

  return (
    <>
      <Header
        claimClass="claimVisible"
        logoSrc={servantesLogo}
        logoClass="servantesLogo"
        onLanguageChange={onLanguageChange}
      />
      <section className="pageSection mainSection">
        <h2>{dictionary.contactPage.title}</h2>
        <div className="contactWrapper">
          <h3>Servantes s.r.o.</h3>
          <p>
            Neklanova 150/38,
            <br />
            Vyšehrad, 128 00 Praha
            <br />
            {dictionary.contactPage.czechrep}
          </p>
          {dictionary.contactPage.address} Jan Melvil Publishing,
          <br />
          Roubalova 383/13 602 00 Brno <br />
          {dictionary.contactPage.czechrep}
          <p>
            <strong>{dictionary.contactPage.contactSubtitle}</strong>
            <br />
            {dictionary.contactPage.generalInfo}
            <a href="info@servantes.cz">info@servantes.cz</a> <br />
            {userLang === 'en' ? (
              <>
                <span>{dictionary.contactPage.internationalSales}: </span>
                <a href="mailto:scott.hudson@servantes.cz">
                  scott.hudson@servantes.cz
                </a>
              </>
            ) : null}
            {userLang === 'cs' ? (
              <>
                <span>{dictionary.contactPage.productInfo}: </span>
                <a href="mailto:zuzana.strachotova@servantes.cz">
                  zuzana.strachotova@servantes.cz
                </a>
              </>
            ) : null}
            <br />
            {dictionary.contactPage.customerSupport}{' '}
            <a href="mailto:zuzana.strachotova@melvil.cz">
              {dictionary.contactPage.customerSupportEmail}
            </a>
          </p>{' '}
          <p>{dictionary.contactPage.phone}+420 737 103 574</p>
          <p>
            {dictionary.contactPage.vat1} <br />
            {dictionary.contactPage.vat2}
          </p>
          <p>
            {dictionary.contactPage.regNumber} C 342432/MSPH Městský soud v
            Praze <br />
            {dictionary.contactPage.executives} Petr Hrachovec, Mgr. Vít Šebor,
            Mgr. Tomáš Baránek, Václav Soukup
          </p>
          <p>
            {dictionary.contactPage.bankingInfo} Raiffeisenbank a.s. <br />
            {dictionary.contactPage.bankAccount}838428/5500
            <br />
            IBAN: CZ41 5500 0000 0000 0083 8428
            <br />
            BIC: RZBCCZPP
          </p>
        </div>
      </section>
      <img className="contactImage" src={contact} alt="" />
      <Footer />
    </>
  );
}

export default Contact;
