import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import phone from '../../assets/bluephone.svg';

function JacobPrice() {
  const { dictionary, userLang } = useLanguage();

  return (
    <>
      <section className="mainSection">
        <h2>{dictionary.miguel.price.priceTitle}</h2>
        <div className="priceTable">
          <div className="priceTableHeader miguelTableHeader jacobPriceHeader">
            {dictionary.jacob.price.priceSubtitle}
          </div>
          <div className="priceTableBody miguelTableBody">
            <h3>{dictionary.jacob.price.costsAmount}</h3>
            <p>{dictionary.jacob.price.priceInfo}</p>
            <span>{dictionary.jacob.price.priceInfo2}</span>
            <a
              className="button"
              href={
                userLang === 'cs'
                  ? 'https://airtable.com/shr3LUDFGhMVFifXz'
                  : 'https://airtable.com/shreGt5YC2I7eS7zv'
              }
              target="_blank"
              rel="noreferrer"
            >
              {dictionary.miguel.price.priceBtn}
            </a>
          </div>
        </div>
      </section>
      <div className="helpWrapper">
        <p>{dictionary.jacob.help}</p>
        <div className="helpContact">
          <img className="helpIcon" src={phone} alt="ikona telefonu" />
          <span>+420 724 362 423</span>
        </div>
      </div>{' '}
    </>
  );
}

export default JacobPrice;
