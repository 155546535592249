import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import Help from '../productPageComponents/Help';

function MiluskaPrice() {
  const { dictionary } = useLanguage();

  return (
    <>
      <section className="mainSection">
        <h2>{dictionary.miluska.price.priceTitle}</h2>
        <div className="priceTable">
          <div className="priceTableHeader miguelTableHeader miluskaTableHeader">
            {dictionary.miluska.price.priceSubtitle}
          </div>
          <div className="priceTableBody miguelTableBody">
            <h3>{dictionary.miluska.price.costsAmount}</h3>
            <p>{dictionary.miluska.price.priceInfo}</p>
            <a className="button" href="mailto:podpora@servantes.cz">
              {dictionary.miguel.price.priceBtn}
            </a>
          </div>
        </div>
      </section>
      <Help />
    </>
  );
}

export default MiluskaPrice;
