import React from 'react';
import Header from '../components/headerComponents/Header';
import MiguelPrestaFooter from '../components/miguelPrestaComponents/MiguelPrestaFooter';
import miguelWoo from '../assets/miguelwoo.png';
import MiguelPrestaStats from '../components/miguelPrestaComponents/MiguelPrestaStats';
import MiguelPrestaGuide from '../components/miguelPrestaComponents/MiguelPrestaGuide';
import MiguelPrestaCaseStudy from '../components/miguelPrestaComponents/MiguelPrestaCaseStudy';
import MiguelPrestaReferences from '../components/miguelPrestaComponents/MiguelPrestaReferences';
import MiguelPrestaPricing from '../components/miguelPrestaComponents/MiguelPrestaPricing';
import MiguelPrestaFaq from '../components/miguelPrestaComponents/MiguelPrestaFaq';
import MiguelPrestaAdvantages from '../components/miguelPrestaComponents/MiguelPrestaAdvantages';
import MiguelPrestaCustomers from '../components/miguelPrestaComponents/MiguelPrestaCustomers';
import melvil from '../assets/publishers/melvil.png';
import mediaPlex from '../assets/publishers/mediaplex.png';
import epocha from '../assets/publishers/epocha.png';
import host from '../assets/publishers/host.png';
import mystery from '../assets/publishers/mysterypress.png';
import MiguelPrestaHero from '../components/miguelPrestaComponents/MiguelPrestaHero';
import { Helmet } from 'react-helmet';

const hostArr = [host, 'https://www.hostbrno.cz/'];
const mediaPlexArr = [mediaPlex, 'https://www.mojeromantika.cz/'];
const melvilArr = [melvil, 'https://www.melvil.cz/'];
const epochaArr = [epocha, 'https://www.epocha.cz/'];
const mysteryArr = [mystery, 'https://www.mysterypress.cz/'];

function MiguelWoo() {
  return (
    <>
      <Helmet>
        <title>Sell e-books and audiobooks through WooCommerce</title>
        <meta
          name="description"
          content="Sell your e-books and audiobooks directly on your e-shop. With a high social DRM protection and fast and easy instalation of WooCommerce add-on."
        />{' '}
      </Helmet>
      <Header
        claimClass="claimHidden"
        logoSrc={miguelWoo}
        miguelPrestaHeader="true"
        miguelPresta="true"
      />
      <MiguelPrestaHero
        platform="WooCommerce"
        pluginURL="https://docs.miguel.servantes.cz/en/docs/platforms/woocommerce/install/"
      />
      <MiguelPrestaStats platform="WooCommerce" />
      <MiguelPrestaGuide platform="WooCommerce"/>
      <MiguelPrestaCaseStudy />
      <MiguelPrestaAdvantages platform="WooCommerce" />
      <MiguelPrestaCustomers
        customers={[hostArr, mediaPlexArr, melvilArr, epochaArr, mysteryArr]}
      />
      <MiguelPrestaReferences />
      <MiguelPrestaPricing pluginURL="https://docs.miguel.servantes.cz/en/docs/platforms/woocommerce/install/" />
      <MiguelPrestaFaq />
      <MiguelPrestaFooter />
    </>
  );
}

export default MiguelWoo;
