import React from 'react';
import Header from '../components/headerComponents/Header';
import Footer from '../components/Footer';
import servantesLogo from '../assets/logo.png';
import ReferenceItem from '../components/landingPageComponents/ReferenceItem';
import { useLanguage } from '../context/LanguageContext';
import ProductCustomers from '../components/productPageComponents/ProductCustomers';
import host from '../assets/publishers/host.png';
import fantom from '../assets/publishers/fantom.png';
import melvil from '../assets/publishers/melvil.png';
import tympanum from '../assets/publishers/tympanum.png';
import oneHot from '../assets/publishers/onehotbook.png';
import paseka from '../assets/publishers/paseka.png';
import supraphon from '../assets/publishers/supraphon.png';
import bourdon from '../assets/publishers/bourdon.png';
import publixing from '../assets/publishers/publixing.png';
import grada from '../assets/publishers/Grada.png';
import akropolis from '../assets/publishers/akropolis.png';
import epocha from '../assets/publishers/epocha.png';
import ifp from '../assets/publishers/ifp.png';
import jota from '../assets/publishers/jota.png';
import kazda from '../assets/publishers/kazda.png';
import mediaplex from '../assets/publishers/mediaplex.png';
import albatros from '../assets/publishers/albatrosmedia.svg';
import euromedia from '../assets/publishers/euromedia.jpeg';
import golden from '../assets/publishers/goldenDog.webp';
import leda from '../assets/publishers/leda.svg';
import livingstone from '../assets/publishers/livingstone.jpg';
import carcosa from '../assets/publishers/carcosa.jpg';
import conqer from '../assets/publishers/conqeueer.png';
import kanopa from '../assets/publishers/kanopa.png';
import mysterypress from '../assets/publishers/mysterypress.png';
import '../styles/pages.scss';

const hostArr = [host, 'https://www.hostbrno.cz/'];
const melvilArr = [melvil, 'https://www.melvil.cz/'];
const epochaArr = [epocha, 'https://www.epocha.cz/'];
const fantomArr = [fantom, 'https://www.fantomprint.cz/'];
const tympanumArr = [tympanum, 'https://tympanum.cz/'];
const oneHotArr = [oneHot, 'https://onehotbook.cz/'];
const pasekaArr = [paseka, 'https://www.paseka.cz/'];
const supraArr = [supraphon, 'https://www.supraphon.cz/'];
const bourdonArr = [bourdon, 'https://nakladatelstvi-bourdon.cz/'];
const publixingArr = [publixing, 'https://www.publixing.com/'];
const gradaArr = [grada, 'https://www.grada.cz/'];
const akropArr = [akropolis, 'https://akropolis.info/'];
const ifpArr = [ifp, 'https://www.ifp-publishing.cz/'];
const jotaArr = [jota, 'https://www.jota.cz/'];
const kazdaArr = [kazda, 'https://www.knihykazda.cz/'];
const mediaplexArr = [mediaplex, 'https://www.mojeromantika.cz/'];
const albatrosArr = [albatros, 'https://www.albatrosmedia.cz/'];
const euromediaArr = [euromedia, 'https://www.euromedia.cz/'];
const goldenArr = [golden, 'https://www.goldendog.cz/'];
const ledaArr = [leda, 'https://leda.cz/'];
const livingArr = [livingstone, 'https://www.jonathanlivingston.cz/'];
const carcosaArr = [carcosa, 'https://www.carcosa.cz/'];
const conquerArr = [conqer, 'https://www.conqueer.cz/'];
const kanopaArr = [kanopa, 'https://www.kanopa.cz/'];
const mysterArr = [mysterypress, 'https://www.mysterypress.cz/'];

function References({ onLanguageChange }) {
  const { dictionary } = useLanguage();

  return (
    <>
      <Header
        claimClass="claimVisible"
        logoSrc={servantesLogo}
        logoClass="servantesLogo"
        onLanguageChange={onLanguageChange}
      />
      <div className="mainSection pageSection">
        <h2>{dictionary.referencesPage.referencesTitle}</h2>
        <p style={{ textAlign: 'center' }}>{dictionary.referencesPage.text}</p>
      </div>
      <ProductCustomers
        customers={[
          hostArr,
          melvilArr,
          epochaArr,
          fantomArr,
          tympanumArr,
          oneHotArr,
          pasekaArr,
          supraArr,
          bourdonArr,
          publixingArr,
          gradaArr,
          akropArr,
          ifpArr,
          jotaArr,
          kazdaArr,
          mediaplexArr,
          albatrosArr,
          euromediaArr,
          goldenArr,
          ledaArr,
          livingArr,
          carcosaArr,
          conquerArr,
          kanopaArr,
          mysterArr,
        ]}
      />
      <div className="referencesAll mainSection">
        <ReferenceItem
          referenceText={dictionary.landingPage.references.host.referenceText}
          referencePerson={
            dictionary.landingPage.references.host.referencePerson
          }
          publisher={dictionary.landingPage.references.host.publisher}
        />

        <ReferenceItem
          referenceText={
            dictionary.landingPage.references.fantomPrint.referenceText
          }
          referencePerson={
            dictionary.landingPage.references.fantomPrint.referencePerson
          }
          personPosition={
            dictionary.landingPage.references.fantomPrint.personPosition
          }
          publisher={dictionary.landingPage.references.fantomPrint.publisher}
        />
        <ReferenceItem
          referenceText={dictionary.landingPage.references.melvil.referenceText}
          referencePerson={
            dictionary.landingPage.references.melvil.referencePerson
          }
          personPosition={
            dictionary.landingPage.references.melvil.personPosition
          }
          publisher={dictionary.landingPage.references.melvil.publisher}
        />
        <ReferenceItem
          referenceText={dictionary.landingPage.references.kazda.referenceText}
          referencePerson={
            dictionary.landingPage.references.kazda.referencePerson
          }
          personPosition={
            dictionary.landingPage.references.kazda.personPosition
          }
          publisher={dictionary.landingPage.references.kazda.publisher}
        />
      </div>
      <Footer />
    </>
  );
}

export default References;
