import React from 'react';
import FAQitem from '../productPageComponents/FAQitem';
import faqImg from '../../assets/faqscreenshot.webp';

const faq = {
  question3: 'How does the delivery of audiobooks work?',
  answer3:
    'Miguel also allows you to send out audiobooks to the customers. The zipped MP3 leave from a secure link, so there is no mass distribution of a public link, which could result in illegal downloads. DRM is not yet part of audiobooks but it is planned for the future.',
  question6: 'What does the "customizable" PDF mean?',
  answer6:
    'The interactive wizard allows you to set the screen size for which the resulting PDF is generated. Then you can set the font size, font type and you are able to turn on margins for notes.',
  question5: 'How do we transfer our e-books and audiobooks to Miguel?',
  answer5:
    "Uploading e-book files to Miguel is similar to what you're used to with other sellers. The latest uploaded version of the e-book is always the current version.",
  question4: 'What formats can be used?',
  answer4:
    'The MOBI and PDF formats are automatically converted from the supplied ePUB. The system also automatically checks the validity of the ePUB. Miguel supports the most commonly used MP3 format for audiobooks.',
};

function MiguelPrestaFaq() {
  return (
    <section className="mainSection" id="faq">
      <h2>Frequently Asked Questions</h2>
      <FAQitem faqQuestion={faq.question3} faqAnswer={faq.answer3} />
      <FAQitem faqQuestion={faq.question4} faqAnswer={faq.answer4} />
      <FAQitem faqQuestion={faq.question5} faqAnswer={faq.answer5} />
      <FAQitem
        faqQuestion={faq.question6}
        faqAnswer={faq.answer6}
        faqImg={faqImg}
      />
    </section>
  );
}

export default MiguelPrestaFaq;
