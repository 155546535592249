import React from 'react';

function ProductHero({
  productName,
  productTitle,
  productSubtitle,
  productSubtitle2,
  productSubtitle3,
  sellingPoints,
  productImage,
  productCheck,
  smallerHeroImg,
}) {
  return (
    <section className="productHero">
      <h1>{productName}</h1>
      <h2>{productTitle}</h2>
      <div className="desktopFlex">
        <div>
          <h3>{productSubtitle}</h3>
          {productSubtitle2 && <h3>{productSubtitle2}</h3>}
          {productSubtitle3 && <h3>{productSubtitle3}</h3>}
          <ul style={{ listStyleImage: `url(${productCheck})` }}>
            {sellingPoints.map((point) => (
              <li key={point}>{point}</li>
            ))}
          </ul>
        </div>
        <img
          className={
            smallerHeroImg === 'smallerHeroImg' ? 'smallerHeroImg' : ''
          }
          src={productImage}
          alt="ilustrace produktu"
        />
      </div>
    </section>
  );
}

export default ProductHero;
