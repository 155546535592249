import React from 'react';

function TeamMemberMain({ personName, personPosition, personInfo, personImg }) {
  return (
    <div className="teamMemberMain">
      <h4>{personName}</h4>
      <h5>{personPosition}</h5>
      <p>{personInfo}</p>
      <img src={personImg} alt="" />
    </div>
  );
}

export default TeamMemberMain;
