import React from 'react';
import Header from '../components/headerComponents/Header';
import Roadmap from '../components/landingPageComponents/Roadmap';
import ReferencesLP from '../components/landingPageComponents/ReferencesLP';
import Footer from '../components/Footer';
import servantesLogo from '../assets/logo.png';
import '../styles/landingPage.scss';
import '../styles/pages.scss';

function LandingPage({ onLanguageChange }) {
  return (
    <>
      <>
        <Header
          claimClass="claimVisible"
          logoSrc={servantesLogo}
          logoClass="servantesLogo"
          onLanguageChange={onLanguageChange}
        />
        <Roadmap />
        <ReferencesLP />
        <Footer />
      </>
    </>
  );
}

export default LandingPage;
