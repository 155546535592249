import React from 'react';
import Help from '../productPageComponents/Help';
import { useLanguage } from '../../context/LanguageContext';
import '../../styles/simon.scss';

function SimonPrice() {
  const { dictionary, userLang } = useLanguage();

  return (
    <>
      <section className="mainSection">
        <h2>{dictionary.miguel.price.priceTitle}</h2>
        <div className="priceTable simonTable">
         {/*  <div
            className={`priceTableHeader miguelTableHeader ${
              userLang === 'en' ? 'simonpriceTableHeader' : ''
            }`}
          >
            {dictionary.simon.price.priceSubtitle}
          </div> */}
          <div className="priceTableBody miguelTableBody simonTableBody">
            {userLang === 'cs' ? (
              <>
                {/*      <h3>{dictionary.simon.price.costsAmount}</h3>

                <p>{dictionary.simon.price.priceInfo}</p>
                <span>{dictionary.simon.price.priceInfo2}</span>
                <p>{dictionary.simon.price.priceInfo3}</p> */}
                <p className="simonpricetemporary">
                  Objevte kouzlo automatického vytváření e-knih se Simonem a
                  buďte u formování digitální budoucnosti
                  nakladatelství.
                </p>
              </>
            ) : (
              <p className="simonpricetemporary">
                Discover the magic of automatic e-book creation with Simon. Feel
                the rush of being an early adopter and help us shape the future
                of digital storytelling together.
              </p>
            )}
            <a
              className="button simonButton"
              href="mailto:podpora@servantes.cz"
            >
              {userLang === 'cs'
                ? `${dictionary.miguel.price.priceBtn}`
                : 'Contact us'}{' '}
            </a>
          </div>
        </div>
      </section>
      <Help />
    </>
  );
}

export default SimonPrice;
