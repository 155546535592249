import React, { useState, useEffect } from 'react';
import { languageOptions, dictionaryList } from '../utils/languages/languages';
import { useParams } from 'react-router-dom';

export const LanguageContext = React.createContext({
  userLang: 'cs',
  dictionary: dictionaryList.cs,
  userLangChange: () => {},
  setUserLang: () => {},
});

export const LanguageProvider = ({ children }) => {
  const [userLang, setUserLang] = useState(getLocalStorageLanguageValue());

  useEffect(() => {
    const langFromLocalStorage = getLocalStorageLanguageValue();
    if (langFromLocalStorage) setUserLang(langFromLocalStorage);
  }, [window.localStorage.getItem('rcml-lang')]);

  const userLangChange = (selected) => {
    const newLang = languageOptions[selected] ? selected : 'cs';
    setUserLang(newLang);
    window.localStorage.setItem('rcml-lang', newLang);
  };

  const provider = {
    userLang,
    dictionary: dictionaryList[userLang],
    userLangChange,
    setUserLang, // Expose setUserLang in the context
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (preferredLang) => {
  const langFromStorage = getLocalStorageLanguageValue();
  const { lang } = useParams();
  const { setUserLang } = React.useContext(LanguageContext); // Add this line

  useEffect(() => {
    if (preferredLang && preferredLang !== langFromStorage) {
      setUserLang(preferredLang);
    }
  }, [preferredLang, langFromStorage, setUserLang]);

  window.localStorage.setItem(
    'rcml-lang',
    lang && languageOptions.hasOwnProperty(lang) ? lang : langFromStorage,
  );

  return React.useContext(LanguageContext);
};

function getLocalStorageLanguageValue() {
  const langFromLocalStorage = window.localStorage.getItem('rcml-lang');
  if (
    langFromLocalStorage &&
    languageOptions.hasOwnProperty(langFromLocalStorage)
  )
    return langFromLocalStorage;
  else return 'cs';
}
