import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import PublisherLogo from '../PublisherLogo';
import shoptet from '../../assets/publishers/shoptet.png';
import restApi from '../../assets/rest-api.png';
import prestashop from '../../assets/publishers/prestashop.png';
import wc from '../../assets/publishers/woocommerce.png';

function MiguelAdvantages() {
  const { dictionary } = useLanguage();

  return (
    <section className="mainSection">
      <h2>{dictionary.columbo.advantagesTitle}</h2>
      <div className="advantagesWrapper">
        <div className="advantages">
          <h3>{dictionary.miguel.advantages.onesubtitle}</h3>
          <ul>
            <li>{dictionary.miguel.advantages.oneli1}</li>
            <li>{dictionary.miguel.advantages.oneli2}</li>
            <li>{dictionary.miguel.advantages.oneli3}</li>
          </ul>
        </div>
        <div className="advantages">
          <h3>{dictionary.miguel.advantages.twosubtitle}</h3>
          <ul>
            <li>{dictionary.miguel.advantages.twoli1}</li>
            <li>{dictionary.miguel.advantages.twoli2}</li>
            <li>{dictionary.miguel.advantages.twoli3}</li>
            <li>{dictionary.miguel.advantages.twoli4}</li>
          </ul>
        </div>
        <div className="advantages">
          <h3>{dictionary.miguel.advantages.threesubtitle}</h3>
          <ul>
            <li>{dictionary.miguel.advantages.threeli1}</li>
            <li>{dictionary.miguel.advantages.threeli2}</li>
            <li>{dictionary.miguel.advantages.threeli3}</li>
          </ul>
        </div>
        <div className="advantages">
          <h3>{dictionary.miguel.advantages.foursubtitle}</h3>
          <ul>
            <li>{dictionary.miguel.advantages.fourli1}</li>
            <li>{dictionary.miguel.advantages.fourli2}</li>
            <li>{dictionary.miguel.advantages.fourli3}</li>
          </ul>
        </div>
      </div>
      <h3 className="subtitle">{dictionary.miguel.advantages.titleShop}</h3>
      <div className="advantagesWrapper">
        <a
          href="https://doplnky.shoptet.cz/miguel-e-knihy"
          target="_blank"
          rel="noreferrer"
        >
          <PublisherLogo publisherLogo={shoptet} />
        </a>
        <a
          href="https://docs.miguel.servantes.cz/docs/platforms/"
          target="_blank"
          rel="noreferrer"
        >
          <PublisherLogo publisherLogo={restApi} />
        </a>
        <a
          href="https://docs.miguel.servantes.cz/docs/platforms/"
          target="_blank"
          rel="noreferrer"
        >
          <PublisherLogo publisherLogo={prestashop} />
        </a>
        <a
          href="https://docs.miguel.servantes.cz/docs/platforms/"
          target="_blank"
          rel="noreferrer"
        >
          <PublisherLogo publisherLogo={wc} />
        </a>
      </div>
    </section>
  );
}

export default MiguelAdvantages;
