import React, { useEffect } from 'react';
import Header from '../components/headerComponents/Header';
import Footer from '../components/Footer';
import simonLogo from '../assets/simon_logo.png';
import { useLanguage } from '../context/LanguageContext';
import '../styles/simon.scss';

function SimonForm() {
  const { userLang } = useLanguage();

  useEffect(() => {
    // Create and append the script element
    const script = document.createElement('script');
    script.src = 'https://static.airtable.com/js/embed/embed_snippet_v1.js';
    script.async = true;
    document.body.appendChild(script);

    // Return a cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array to ensure this effect runs only once

  return (
    <>
      <Header
        claimClass="claimHidden"
        logoClass="productLogo"
        logoSrc={simonLogo}
      />
      {userLang === 'en' ? (
        <iframe
          className="airtable-embed airtable-dynamic-height"
          src="https://airtable.com/embed/appFjvgybVlZTxPr0/pagISlQfJVY1vfVq2/form"
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="1000"
          style={{ background: 'transparent', border: '1px solid #ccc' }}
          title="Airtable Embed"
        ></iframe>
      ) : (
        <iframe
          class="airtable-embed"
          src="https://airtable.com/embed/appFjvgybVlZTxPr0/paggoAFxk5bozK2rO/form"
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="1000"
          style={{ background: 'transparent', border: '1px solid #ccc' }}
          title="Airtable Embed"
        ></iframe>
      )}
      <Footer />
    </>
  );
}

export default SimonForm;
