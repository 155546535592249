import React from 'react';
import { useLanguage } from '../../context/LanguageContext';

function EditaPrice() {
  const { dictionary } = useLanguage();

  return (
    <section className="mainSection">
      <h2>{dictionary.edita.price.title}</h2>
      <p>{dictionary.edita.price.priceExplanation}</p>
      <div className="priceTable">
        <div className="priceTableHeader editaPriceHeader">
          <h4>{dictionary.edita.price.column1}</h4>
          <h4>{dictionary.edita.price.column2}</h4>
        </div>
        <div className="priceTableBody">
          <div className="column column1">
            <span>1 - 49</span>
            <span>50 - 99</span>
            <span>100 - 249</span>
            <span>250 - 499</span>
            <span>500 +</span>
          </div>
          <div className="column column2">
            <span>950 Kč</span>
            <span>1 350 Kč</span>
            <span>1 850 Kč</span>
            <span>2 450 Kč</span>
            <span>{dictionary.columbo.price.priceIndividual}</span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EditaPrice;
