import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import cartJmp from '../../assets/miguel_jmp2.png';

function MiguelPreview() {
  const { dictionary } = useLanguage();

  return (
    <section className="mainSection miguelPreview">
      <div>
        <h2>{dictionary.miguel.previewTitle}</h2>
        <p>{dictionary.miguel.previewNote}</p>
      </div>
      <img src={cartJmp} alt="" />
    </section>
  );
}

export default MiguelPreview;
