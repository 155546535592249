import React from 'react';
import PublisherLogo from '../PublisherLogo';

function ProductCustomers({ customers, customerUrl }) {
  const customerData = customers
    .map((customer) => {
      if (typeof customer === 'string') {
        return { customer: customer };
      } else if (typeof customer === 'object' && customer.customerName) {
        return {
          customer: customer.customerName,
          customerUrl: customer.customerUrl,
        };
      }
      return null;
    })
    .filter(Boolean);
  console.log(customerData);
  return (
    <section className="mainSection customerWrapper">
      {customerData.map((customer, index) => (
        <div key={index}>
          {customer.customerUrl ? (
            <a href={customer.customerUrl} target="_blank" rel="noreferrer">
              <PublisherLogo publisherLogo={customer.customer} />
            </a>
          ) : (
            <PublisherLogo publisherLogo={customer.customer} />
          )}
        </div>
      ))}
    </section>
  );
}

export default ProductCustomers;
