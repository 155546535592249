import React from 'react';
import { useLanguage } from '../../context/LanguageContext';

import FAQitem from './FAQitem';

function FAQ({
  faqQuestion1,
  faqAnswer1,
  faqAnswer2,
  faqQuestion2,
  faqAnswer3,
  faqQuestion3,
  faqQuestion4,
  faqAnswer4,
  faqQuestion5,
  faqAnswer5,
  faqAnswer6,
  faqQuestion6,
}) {
  const { dictionary } = useLanguage();

  return (
    <section className="mainSection">
      <h2>{dictionary.columbo.faqTitle}</h2>
      <FAQitem faqQuestion={faqQuestion1} faqAnswer={faqAnswer1} />
      <FAQitem faqQuestion={faqQuestion2} faqAnswer={faqAnswer2} />
      <FAQitem faqQuestion={faqQuestion3} faqAnswer={faqAnswer3} />
      {faqQuestion4 && (
        <FAQitem faqQuestion={faqQuestion4} faqAnswer={faqAnswer4} />
      )}
      {faqQuestion5 && (
        <FAQitem faqQuestion={faqQuestion5} faqAnswer={faqAnswer5} />
      )}
      {faqQuestion6 && (
        <FAQitem faqQuestion={faqQuestion6} faqAnswer={faqAnswer6} />
      )}
    </section>
  );
}

export default FAQ;
