import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import phone from '../../assets/bluephone.svg';

function Help() {
  const { dictionary } = useLanguage();

  return (
    <div className="helpWrapper">
      <p>{dictionary.columbo.help}</p>
      <div className="helpContact">
        <img className="helpIcon" src={phone} alt="ikona telefonu" />
        <span>{dictionary.footer.contactPhone}</span>
      </div>
    </div>
  );
}

export default Help;
