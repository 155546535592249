import React from 'react';
import { NavLink } from 'react-router-dom';

function NavItem({ navItemName, navItemRoute, miguelPresta }) {
  return (
    <li>
      {!miguelPresta && <NavLink to={navItemRoute}>{navItemName}</NavLink>}{' '}
      {miguelPresta && (
        <a className="mp-navItem" href={navItemRoute}>
          {navItemName}
        </a>
      )}
    </li>
  );
}

export default NavItem;
