import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, generatePath, Link } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import NavItem from './NavItem';
import { bool, func } from 'prop-types';
import LanguageSwitch from '../../utils/LanguageSwitch';
import Select from 'react-select';
import servantes from '../../assets/logo.png';
import '../../styles/nav.scss';

const options = [
  {
    value: 'Jacob',
    url: '/:lang/jacob',
  },
  {
    value: 'Ella',
    url: '/:lang/ella',
  },
  {
    value: 'Edita',
    url: '/:lang/edita',
  },
  {
    value: 'Miluška',
    url: '/:lang/miluska',
  },
];

const optionsEN = [
  {
    value: 'Add-on for PrestaShop',
    url: '/miguel_prestashop',
  },
  {
    value: 'Add-on for WooCommerce',
    url: '/miguel_woocommerce',
  },
];

function Navigation({ menuOpen, onLanguageChange, miguelPresta }) {
  const { dictionary, userLang } = useLanguage();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState(null);
  const [showSelect, setShowSelect] = useState(false);

  const navigate = useNavigate();

  //click on select - navigate to selected option
  useEffect(() => {
    const option = options
      .map(
        (x) =>
          (x.label = (
            <NavItem
              navItemRoute={generatePath(x.url, { lang: userLang })}
              navItemName={x.value}
            />
          )),
      )
      .find((option) => option.value === location.pathname);
    if (option) {
      setSelectedOption(option);
    }
  }, [location.pathname, userLang]);

  function handleChange(option) {
    setSelectedOption(option);
    navigate(option.label.props.navItemRoute);
  }

  //click on select - navigate to selected option EN version
  useEffect(() => {
    const optionEN = optionsEN
      .map(
        (x) =>
          (x.label = (
            <NavItem navItemRoute={generatePath(x.url)} navItemName={x.value} />
          )),
      )
      .find((option) => option.value === location.pathname);
    if (optionEN) {
      setSelectedOption(optionEN);
    }
  }, [location.pathname, userLang]);

  function handleENChange(option) {
    setSelectedOption(option);
    navigate(option.label.props.navItemRoute);
  }

  //select visible only once screen is bigger than 1100px
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1100) {
        setShowSelect(true);
      } else {
        setShowSelect(false);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className={`navigation ${menuOpen ? 'menuOpen' : ''}`}>
      {!miguelPresta && (
        <ul>
          <NavItem
            navItemRoute={generatePath('/:lang/miguel', { lang: userLang })}
            navItemName="Miguel"
          />
          {userLang === 'cs' && (
            <NavItem
              navItemRoute={generatePath('/:lang/columbo', { lang: userLang })}
              navItemName="Columbo"
            />
          )}
          <NavItem
            navItemRoute={generatePath('/:lang/simon', { lang: userLang })}
            navItemName="Simon"
          />
          {showSelect && userLang === 'cs' ? (
            <Select
              options={options}
              onChange={handleChange}
              value={selectedOption}
              placeholder="Další produkty"
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  border: 'none',
                  padding: '0 24px',
                  cursor: 'pointer',
                }),
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  marginLeft: '0',
                  color: '#173442',
                  fontSize: '19px',
                  fontWeight: '600',
                }),
                ValueContainer: (baseStyles) => ({
                  ...baseStyles,
                  padding: '0 8px 0 0',
                }),
                indicatorSeparator: (baseStyles) => ({
                  ...baseStyles,
                  display: 'none',
                }),
                indicatorContainer: (baseStyles) => ({
                  ...baseStyles,
                  paddingLeft: '0',
                }),
                Svg: (baseStyles) => ({ ...baseStyles, fill: '#173442' }),
              }}
            />
          ) : showSelect && userLang === 'en' ? (
            <>
              <NavItem
                navItemRoute={generatePath('/:lang/jacob', { lang: userLang })}
                navItemName="Jacob"
              />
              <NavItem
                navItemRoute={generatePath('/:lang/ella', { lang: userLang })}
                navItemName="Ella"
              />
              <Select
                options={optionsEN}
                onChange={handleENChange}
                value={selectedOption}
                placeholder="Miguel add-ons"
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    border: 'none',
                    padding: '0 24px',
                    cursor: 'pointer',
                  }),
                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    marginLeft: '0',
                    color: '#173442',
                    fontSize: '19px',
                    fontWeight: '600',
                  }),
                  ValueContainer: (baseStyles) => ({
                    ...baseStyles,
                    padding: '0 8px 0 0',
                  }),
                  indicatorSeparator: (baseStyles) => ({
                    ...baseStyles,
                    display: 'none',
                  }),
                  indicatorContainer: (baseStyles) => ({
                    ...baseStyles,
                    paddingLeft: '0',
                  }),
                  Svg: (baseStyles) => ({ ...baseStyles, fill: '#173442' }),
                }}
              />
            </>
          ) : (
            <>
              <NavItem
                navItemRoute={generatePath('/:lang/jacob', { lang: userLang })}
                navItemName="Jacob"
              />
              <NavItem
                navItemRoute={generatePath('/:lang/ella', { lang: userLang })}
                navItemName="Ella"
              />
              {userLang === 'cs' && (
                <NavItem
                  navItemRoute={generatePath('/:lang/edita', {
                    lang: userLang,
                  })}
                  navItemName="Edita"
                />
              )}
              {userLang === 'cs' && (
                <NavItem
                  navItemRoute={generatePath('/:lang/miluska', {
                    lang: userLang,
                  })}
                  navItemName="Miluška"
                />
              )}
              {userLang === 'en' && (
                <NavItem
                  navItemRoute={generatePath('/miguel_prestashop')}
                  navItemName="Miguel add-on for PrestaShop"
                />
              )}
              {userLang === 'en' && (
                <NavItem
                  navItemRoute={generatePath('/miguel_woocommerce')}
                  navItemName="Miguel add-on for WooCommerce"
                />
              )}
            </>
          )}
          <div className="secondaryNavigation-mobile secondaryNavigation">
            <NavItem
              navItemRoute="/"
              navItemName={dictionary.navigation.home}
            />

            <NavItem
              navItemRoute={generatePath('/:lang/reference', {
                lang: userLang,
              })}
              navItemName={dictionary.navigation.references}
            />
            <NavItem
              navItemRoute={generatePath('/:lang/about', { lang: userLang })}
              navItemName={dictionary.navigation.about}
            />
            <NavItem
              navItemRoute={generatePath('/:lang/contact', { lang: userLang })}
              navItemName={dictionary.navigation.contact}
            />
            <LanguageSwitch onLanguageSwitch={onLanguageChange} />
          </div>
        </ul>
      )}
      {miguelPresta && (
        <>
          <Link to="/" className="mp-servantes-logoWrapper">
            <img src={servantes} alt="" className="mp-servantes-logo" />
          </Link>
          <div className="secondaryNavigation-mobile secondaryNavigation mp-mobile-nav">
            <ul>
              <NavItem
                navItemRoute="#setup"
                navItemName="Setup"
                miguelPresta={miguelPresta}
              />
              <NavItem
                navItemRoute="#advantages"
                navItemName="Advantages"
                miguelPresta={miguelPresta}
              />
              <NavItem
                navItemRoute="#references"
                navItemName="References"
                miguelPresta={miguelPresta}
              />
              <NavItem
                navItemRoute="#faq"
                navItemName="FAQ"
                miguelPresta={miguelPresta}
              />
            </ul>
          </div>
        </>
      )}
      <ul className="secondaryNavigation-desktop secondaryNavigation">
        {!miguelPresta && (
          <>
            <NavItem
              navItemRoute="/"
              navItemName={dictionary.navigation.home}
            />
            <NavItem
              navItemRoute={generatePath('/:lang/reference', {
                lang: userLang,
              })}
              navItemName={dictionary.navigation.references}
            />
            <NavItem
              navItemRoute={generatePath('/:lang/about', { lang: userLang })}
              navItemName={dictionary.navigation.about}
            />
            <NavItem
              navItemRoute={generatePath('/:lang/contact', { lang: userLang })}
              navItemName={dictionary.navigation.contact}
            />
            <LanguageSwitch onLanguageSwitch={onLanguageChange} />
          </>
        )}
        {miguelPresta && (
          <>
            <NavItem
              navItemRoute="#setup"
              navItemName="Setup"
              miguelPresta={miguelPresta}
            />
            <NavItem
              navItemRoute="#advantages"
              navItemName="Advantages"
              miguelPresta={miguelPresta}
            />
            <NavItem
              navItemRoute="#references"
              navItemName="References"
              miguelPresta={miguelPresta}
            />
            <NavItem
              navItemRoute="#faq"
              navItemName="FAQ"
              miguelPresta={miguelPresta}
            />
          </>
        )}
      </ul>
    </nav>
  );
}

Navigation.propTypes = {
  menuOpen: bool.isRequired,
  onLanguageChange: func.isRequired,
};

export default Navigation;
