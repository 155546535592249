import React, { useEffect } from 'react';
import Header from '../components/headerComponents/Header';
import Footer from '../components/Footer';
import simonLogo from '../assets/simon_logo.png';
import '../styles/simon.scss';

function SimonForm() {
  useEffect(() => {
    // Create and append the script element
    const script = document.createElement('script');
    script.src =
      'https://airtable.com/embed/appFjvgybVlZTxPr0/pagf4ju5XxCnqyky4/form';
    script.async = true;
    document.body.appendChild(script);

    // Return a cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array to ensure this effect runs only once

  return (
    <>
      <Header
        claimClass="claimHidden"
        logoClass="productLogo"
        logoSrc={simonLogo}
      />
      <iframe
        className="airtable-embed"
        src="https://airtable.com/embed/appFjvgybVlZTxPr0/pagf4ju5XxCnqyky4/form"
        frameborder="0"
        width="100%"
        height="1300"
        style={{ background: 'transparent', border: '1px solid #ccc' }}
        title="Airtable Embed"
      ></iframe>
      <Footer />
    </>
  );
}

export default SimonForm;
