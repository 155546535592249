import React from 'react';
import host from '../../assets/mp-ref-host.png';

function MiguelPrestaReferences() {
  return (
    <section className="mainSection mp-references" >
      <h2>Publisher: "It's an Indispensable Tool"</h2>
      <div className="mp-references-all">
        <div className="mp-references-item">
          <img className="mp-references-book" src={host} alt="obálka knihy" />
          <div className="mp-references-blockquote">
            <blockquote>
              <p>
                "We are really glad that we decided to use Miguel. It is a
                wonderful tool that allows us to efficiently sell our e-books
                online with ease. The application has an intuitive interface and
                it is easy to learn everything about it. Thanks to its reports
                and graphs you are always up to date on how sales are
                developing. The team behind Miguel keeps coming up with new
                updates and improvements. Whenever we needed help, our questions
                were answered in a timely manner, meaning immediately, with a
                smile and a professional attitude.
              </p>{' '}
              <p>
                Thanks to the fact that we can now sell e-books directly through
                our e-shop, we are able to avoid the fees charged by
                distribution platforms. This has allowed us to increase our
                profits and to have an immediate overview of our sales figures.
                Miguel has brought us significant benefits and has made our
                e-book business easier. It is an indispensable tool for anyone
                who wants to successfully sell e-books online."
              </p>
            </blockquote>
            <cite className="mp-references-quote">
              Martina Skrabalova, sales manager of e-books and audiobooks in <a
                href="https://www.hostbrno.cz/"
                target="_blank"
                rel="noreferrer"
              >HOST publishing house
              </a>
            </cite>
          </div>
          {/*  <div className="mp-references-item">
          <img src="" alt="" />
          <div className="mp-references-blockquote">
            <blockquote>
              Měl jsem skvělý zážitek s Miguelem. Bylo velmi jednoduché vytvořit a publikovat můj ebook pomocí jejich nástrojů a šablon. Líbilo se mi, jak jsem mohl přidat interaktivní prvky jako videa a kvízy, abych udělal mou knihu zajímavější. Můj ebook je k dispozici na všech zařízeních.
            </blockquote>
            <div className="mp-reference-quote">
              <strong>Marek Jankovič</strong>
              <span>CTO</span>
              <span>HOST</span>
            </div>
          </div>
        </div> */}
        </div>
      </div>
    </section>
  );
}

export default MiguelPrestaReferences;
