import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import calendar from '../../assets/calendar.svg';
import browser from '../../assets/browser.svg';
import listing from '../../assets/listing_blue.svg';
import brain from '../../assets/brain.svg';

function ColumboAdvantages() {
  const { dictionary } = useLanguage();

  return (
    <section className="mainSection">
      <h2>{dictionary.columbo.advantagesTitle}</h2>
      <div className="advantagesWrapper">
        <div className="advantages">
          <img src={calendar} alt="calendar icon" />
          <p>{dictionary.columbo.advantages.p1}</p>
        </div>
        <div className="advantages">
          <img src={browser} alt="browser icon" />
          <p>{dictionary.columbo.advantages.p2}</p>
        </div>
        <div className="advantages">
          <img src={listing} alt="listing icon" />
          <p>{dictionary.columbo.advantages.p3}</p>
        </div>
        <div className="advantages">
          <img src={brain} alt="brain icon" />
          <p>{dictionary.columbo.advantages.p4}</p>
        </div>
      </div>
    </section>
  );
}

export default ColumboAdvantages;
