import React from 'react';
import { bool, func } from 'prop-types';

function Hamburger({ menuOpen, setMenuOpen }) {
  return (
    <button
      className={`hamburger ${menuOpen ? 'open' : ''}`}
      onClick={() => setMenuOpen(!menuOpen)}
    >
      <span></span>
      <span></span>
      <span></span>
      <span>Menu</span>
    </button>
  );
}

Hamburger.propTypes = {
  menuOpen: bool.isRequired,
  setMenuOpen: func.isRequired,
};

export default Hamburger;
