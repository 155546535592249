import React from 'react';

function PublisherLogo({ publisherLogo }) {
  return (
    <div className="publisherLogo">
      <img src={publisherLogo} alt="logo nakladatelství" />
    </div>
  );
}

export default PublisherLogo;
