import React from 'react';

function TeamMemberOthers({
  personImg,
  personName,
  personPosition,
  personDetail,
}) {
  return (
    <div className="teamMemberOthers">
      <img src={personImg} alt="" />
      <h4>{personName}</h4>
      <h5>{personPosition}</h5>
      <h5>{personDetail}</h5>
    </div>
  );
}

export default TeamMemberOthers;
