import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import Help from '../productPageComponents/Help';

function ColumboPrice() {
  const { dictionary } = useLanguage();

  return (
    <section className="mainSection">
      <h2>{dictionary.columbo.price.priceTitle}</h2>
      <div className="priceTable columboPriceTable">
        <div className="priceTableHeader columboTableHeader">
          <h4>{dictionary.columbo.price.column1}</h4>
          <h4>{dictionary.columbo.price.column2}</h4>
        </div>
        <div className="priceTableBody">
          <div className="column column1">
            <span>2 - 49</span>
            <span>50 - 99</span>
            <span>100 - 199</span>
            <span>200 - 299</span>
            <span>300 - 499</span>
            <span>500 - 999</span>
            <span>1 000+</span>
          </div>
          <div className="column column2">
            <span>1 950Kč - 3 980Kč</span>
            <span>2 190Kč - 4 440Kč</span>
            <span>2 700Kč - 5 680Kč</span>
            <span>3 280Kč - 6 915Kč</span>
            <span>4 380Kč - 9 190Kč</span>
            <span>6 440Kč - 12 870Kč</span>
            <span>{dictionary.columbo.price.priceIndividual}</span>
          </div>
        </div>
        <p>{dictionary.columbo.price.priceInfo1}</p>
        <p>{dictionary.columbo.price.priceInfo2}</p>
      </div>
      <Help />
    </section>
  );
}

export default ColumboPrice;
