import React from 'react';
import Header from '../components/headerComponents/Header';
import Footer from '../components/Footer';
import { useLanguage } from '../context/LanguageContext';
import miguelLogo from '../assets/miguel_logo.png';
import ProductHero from '../components/productPageComponents/ProductHero';
import ProductCustomers from '../components/productPageComponents/ProductCustomers';
import MiguelAdvantages from '../components/miguelPageComponents/MiguelAdvantages';
import ProductExplanation from '../components/productPageComponents/ProductExplanation';
import MiguelPreview from '../components/miguelPageComponents/MiguelPreview';
import MiguelPrice from '../components/miguelPageComponents/MiguelPrice';
import FAQ from '../components/productPageComponents/FAQ';
import miguel from '../assets/miguel.svg';
import ifp from '../assets/publishers/ifp.png';
import melvil from '../assets/publishers/melvil.png';
import mediaPlex from '../assets/publishers/mediaplex.png';
import akropolis from '../assets/publishers/akropolis.png';
import epocha from '../assets/publishers/epocha.png';
import host from '../assets/publishers/host.png';
import mystery from '../assets/publishers/mysterypress.png';
import livingstone from '../assets/publishers/livingstone.jpg';
import conquer from '../assets/publishers/conqeueer.png';
import carcosa from '../assets/publishers/carcosa.jpg';
import kanopa from '../assets/publishers/kanopa.png';
import miguelCheck from '../assets/check_miguel.svg';
import migArrow from '../assets/arrow_miguel.svg';
import MiguelQuote from '../components/miguelPageComponents/MiguelQuote';
import '../styles/miguel.scss';
import '../styles/products.scss';

function Miguel({ onLanguageChange }) {
  const { dictionary } = useLanguage();

  return (
    <>
      <Header
        claimClass="claimHidden"
        logoSrc={miguelLogo}
        logoClass="productLogo"
        onLanguageChange={onLanguageChange}
      />
      <ProductHero
        productName="Miguel"
        productTitle={dictionary.miguel.title}
        productSubtitle={dictionary.miguel.subtitle}
        sellingPoints={[
          dictionary.miguel.sellingPoints.li1,
          dictionary.miguel.sellingPoints.li2,
          dictionary.miguel.sellingPoints.li3,
          dictionary.miguel.sellingPoints.li4,
          dictionary.miguel.sellingPoints.li5,
          dictionary.miguel.sellingPoints.li6,
          dictionary.miguel.sellingPoints.li7,
          dictionary.miguel.sellingPoints.li8,
          dictionary.miguel.sellingPoints.li9,
        ]}
        productImage={miguel}
        productCheck={miguelCheck}
      />
      <ProductExplanation
        explanation1={dictionary.miguel.explanation.p1}
        explanation2={dictionary.miguel.explanation.p2}
        explanation3={dictionary.miguel.explanation.p3}
        explanation4={dictionary.miguel.explanation.p4}
        arrow={migArrow}
      />
      <ProductCustomers
        customers={[
          akropolis,
          melvil,
          ifp,
          mediaPlex,
          epocha,
          host,
          mystery,
          livingstone,
          conquer,
          carcosa,
          kanopa,
        ]}
      />
      <MiguelAdvantages />
      <MiguelQuote />
      <MiguelPreview />
      <MiguelPrice />
      <FAQ
        faqQuestion1={dictionary.miguel.faq.question1}
        faqAnswer1={dictionary.miguel.faq.answer1}
        faqQuestion2={dictionary.miguel.faq.question2}
        faqAnswer2={dictionary.miguel.faq.answer2}
        faqQuestion3={dictionary.miguel.faq.question3}
        faqAnswer3={dictionary.miguel.faq.answer3}
        faqQuestion4={dictionary.miguel.faq.question4}
        faqAnswer4={dictionary.miguel.faq.answer4}
        faqQuestion5={dictionary.miguel.faq.question5}
        faqAnswer5={dictionary.miguel.faq.answer5}
        faqQuestion6={dictionary.miguel.faq.question6}
        faqAnswer6={dictionary.miguel.faq.answer6}
      />
      <Footer />
    </>
  );
}

export default Miguel;
