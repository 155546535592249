import React from 'react';
import miguel from '../../assets/miguel.svg';
import check from '../../assets/check_miguel.svg';

function MiguelPrestaHero({ platform, pluginURL }) {
  return (
    <section className="productHero mp-hero">
      <div className="mp-hero-content">
        <h1>
          Sell <span style={{ whiteSpace: 'nowrap' }}>e-books </span>
          and audiobooks through {platform} with Miguel {platform === 'PrestaShop' ? 'add-on' : 'plugin'}
        </h1>
        <ul style={{ listStyleImage: `url(${check})` }}>
          <li>All formats: ePUB, PDF, MOBI and MP3 </li>
          <li>Effective anti-piracy protection (DRM)</li>
          <li>Start selling in 15 minutes</li>
          <li>Direct payment from customer</li>
          <li>Free installation, 10% comission on orders</li>
        </ul>
        <a className="button mp-button" href={pluginURL}>
          Download {platform === 'PrestaShop' ? 'add-on' : 'plugin'}
        </a>
      </div>
      <img src={miguel} alt="ilustrace produktu" />
    </section>
  );
}

export default MiguelPrestaHero;
