import React from 'react';
import star from '../../assets/star.png';
import check from '../../assets/check.png';
import growth from '../../assets/growth.svg';
import handshake from '../../assets/handshake.svg';

function MiguelPrestaStats({ platform }) {
  return (
    <div className="mp-stats mainSection">
      <div className="mp-stats-item">
        <div className="mp-stats-icon">
          <img src={check} alt="" />
        </div>
        <span>Product of an established developer Servantes</span>
      </div>
      <div className="mp-stats-item">
        <div className="mp-stats-icon">
          <img src={handshake} alt="" />
        </div>
        <span>Risk-free thanks to no installation fee</span>
      </div>
      <div className="mp-stats-item">
        <div className="mp-stats-icon">
          <img src={star} alt="" />
        </div>
        <span className="tooltip-container">
          The most secure {platform === 'PrestaShop' ? 'add-on' : 'plugin'} for
          e-books and audiobooks on {platform}
        </span>
      </div>
      <div className="mp-stats-item">
        <div className="mp-stats-icon">
          <img src={growth} alt="" />
        </div>
        <span>
          20-40% higher profit margins than traditional sales channels
        </span>
      </div>
    </div>
  );
}

export default MiguelPrestaStats;
