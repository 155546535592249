import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import airtable from '../../assets/apps/airtable.png';
import integromat from '../../assets/apps/integromat.png';
import apple from '../../assets/apps/apple.png';
import synology from '../../assets/apps/synology.jpg';
import zapier from '../../assets/apps/zapier.png';
import basecamp from '../../assets/apps/basecamp.png';
import googleApps from '../../assets/apps/googleApps.png';

const jacobApps = [
  airtable,
  integromat,
  apple,
  synology,
  zapier,
  basecamp,
  googleApps,
];

function JacobApps() {
  const { dictionary } = useLanguage();

  return (
    <section className="mainSection">
      <h2>{dictionary.jacob.apps.title}</h2>
      <div className="jacobAppsWrapper">
        {jacobApps.map((app) => {
          return (
            <div className="jacobApp" key={app}>
              <div className="jacobAppLogo">
                <img src={app} alt="" />
              </div>
              <p>{dictionary.jacob.apps.apple}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default JacobApps;
