import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import ellaLogo from '../../assets/ella_logo.webp';
import simonLogo from '../../assets/simon_logo.png';
import jacobLogo from '../../assets/jacob_logo.png';
import migLogo from '../../assets/miguel_logo.png';
import colLogo from '../../assets/columbo_logo.png';
import columbo from '../../assets/columbo.svg';
import miguel from '../../assets/miguel.svg';
import ella from '../../assets/ella.svg';
import jacob from '../../assets/jacob.svg';
import simon from '../../assets/simon.svg';
import RoadmapItem from './RoadmapItem';

const productColors = {
  columboColor: '#45a6e3',
  miguelColor: '#00C938',
  ellaColor: '#FFA1B3',
  simonColor: '#33cc99',
  jacobColor: '#4A8891',
};

function Roadmap() {
  const { dictionary } = useLanguage();

  return (
    <section className="roadmap">
      <h1 className="lptitle">{dictionary.landingPage.title}</h1>
      <h2 className="lpsubtitle">{dictionary.landingPage.subtitle}</h2>
      <div className="roadmapProductsWrapper">
        <RoadmapItem
          productClaim={dictionary.landingPage.miguel.productClaim}
          productName={dictionary.landingPage.miguel.productName}
          productLogo={migLogo}
          borderColor={productColors.miguelColor}
          gridRow="1"
          gridColumn="1"
          productUrl="/:lang/miguel"
          avatar={miguel}
        />
        <RoadmapItem
          productClaim={dictionary.landingPage.ella.productClaim}
          productName={dictionary.landingPage.ella.productName}
          productLogo={ellaLogo}
          borderColor={productColors.ellaColor}
          order="orderSecond"
          textAlign="right"
          gridRow="1"
          gridColumn="3"
          productUrl="/:lang/ella"
          avatar={ella}
        />
        <RoadmapItem
          productClaim={dictionary.landingPage.simon.productClaim}
          productName={dictionary.landingPage.simon.productName}
          productLogo={simonLogo}
          borderColor={productColors.simonColor}
          gridRow="2"
          gridColumn="2"
          productUrl="/:lang/simon"
          textAlign="center"
          productClassName="centeredProduct"
          avatar={simon}
        />
        <RoadmapItem
          productClaim={dictionary.landingPage.columbo.productClaim}
          productName={dictionary.landingPage.columbo.productName}
          productLogo={colLogo}
          borderColor={productColors.columboColor}
          gridRow="3"
          gridColumn="1"
          productUrl="/:lang/columbo"
          avatar={columbo}
        />
        <RoadmapItem
          productClaim={dictionary.landingPage.jacob.productClaim}
          productName={dictionary.landingPage.jacob.productName}
          productLogo={jacobLogo}
          borderColor={productColors.jacobColor}
          gridRow="3"
          gridColumn="3"
          order="orderSecond"
          textAlign="right"
          productUrl="/:lang/jacob"
          avatar={jacob}
        />
      </div>
    </section>
  );
}

export default Roadmap;
