import React from 'react';

function ReferenceItem({
  referenceText,
  referencePerson,
  personPosition,
  publisher,
}) {
  return (
    <div className="referenceItem referenceQuote">
      <blockquote>
        <p>{referenceText}</p>
      </blockquote>
      <cite>
        <h5>{referencePerson}</h5>
        <h6>
          <span>{personPosition}</span>
          <span>{publisher}</span>
        </h6>
      </cite>
    </div>
  );
}

export default ReferenceItem;
