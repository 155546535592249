import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import PublisherLogo from '../PublisherLogo';
import ReferenceItem from './ReferenceItem';
import melvil from '../../assets/publishers/melvil.png';
import host from '../../assets/publishers/host.png';
import fantom from '../../assets/publishers/fantom.png';
import epocha from '../../assets/publishers/epocha.png';
import paseka from '../../assets/publishers/paseka.png';
import onehot from '../../assets/publishers/onehotbook.png';
import grada from '../../assets/publishers/Grada.png';
import supra from '../../assets/publishers/supraphon.png';
import tympanum from '../../assets/publishers/tympanum.png';
import albatros from '../../assets/publishers/albatrosmedia.svg';
import mediaPlex from '../../assets/publishers/mediaplex.png';
import bourdon from '../../assets/publishers/bourdon.png';
import carcosa from '../../assets/publishers/carcosa.jpg';
import conqueer from '../../assets/publishers/conqeueer.png';
import ifp from '../../assets/publishers/ifp.png';
import kanopa from '../../assets/publishers/kanopa.png';
import mystery from '../../assets/publishers/mysterypress.png';
import publixing from '../../assets/publishers/publixing.png';
import euromedia from '../../assets/publishers/euromedia.jpeg';
import livingstone from '../../assets/publishers/livingstone.jpg';
import audioteka from '../../assets/publishers/audioteka.png';
import leda from '../../assets/publishers/leda.svg';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const publishers = [
  melvil,
  host,
  grada,
  euromedia,
  fantom,
  epocha,
  paseka,
  onehot,
  supra,
  tympanum,
  bourdon,
  publixing,
  mediaPlex,
  leda,
  albatros,
  audioteka,
  mystery,
  livingstone,
  conqueer,
  carcosa,
  kanopa,
  ifp,
];

function ReferencesLP() {
  const { dictionary } = useLanguage();

  return (
    <section className="lpReferenceWrapper">
      <h2 className="referencesTitle">
        {dictionary.landingPage.references.title}
      </h2>
      <div className="publishersWrapper">
        {publishers.map((logo) => (
          <PublisherLogo publisherLogo={logo} />
        ))}
      </div>
      <Carousel
        autoPlay={false}
        infiniteLoop={true}
        showArrows={true}
        showStatus={false}
        swipeable={true}
        showIndicators={true}
        showThumbs={false}
      >
        <ReferenceItem
          referenceText={dictionary.landingPage.references.host.referenceText}
          referencePerson={
            dictionary.landingPage.references.host.referencePerson
          }
          publisher={dictionary.landingPage.references.host.publisher}
        />

        <ReferenceItem
          referenceText={
            dictionary.landingPage.references.fantomPrint.referenceText
          }
          referencePerson={
            dictionary.landingPage.references.fantomPrint.referencePerson
          }
          personPosition={
            dictionary.landingPage.references.fantomPrint.personPosition
          }
          publisher={dictionary.landingPage.references.fantomPrint.publisher}
        />

        <ReferenceItem
          referenceText={dictionary.landingPage.references.melvil.referenceText}
          referencePerson={
            dictionary.landingPage.references.melvil.referencePerson
          }
          personPosition={
            dictionary.landingPage.references.melvil.personPosition
          }
          publisher={dictionary.landingPage.references.melvil.publisher}
        />

        <ReferenceItem
          referenceText={dictionary.landingPage.references.kazda.referenceText}
          referencePerson={
            dictionary.landingPage.references.kazda.referencePerson
          }
          personPosition={
            dictionary.landingPage.references.kazda.personPosition
          }
          publisher={dictionary.landingPage.references.kazda.publisher}
        />
      </Carousel>
    </section>
  );
}

export default ReferencesLP;
