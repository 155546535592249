import React from 'react';
import { useLanguage } from '../../context/LanguageContext';

function ProductExplanation({
  arrow,
  explanation1,
  explanation2,
  explanation4,
  explanation3,
}) {
  const { dictionary } = useLanguage();

  return (
    <section className="mainSection">
      <h2>{dictionary.edita.explanationTitle}</h2>
      <div className="explanationWrapper">
        <div className="productExplanation">
          <div className="productExplanationNumber">1</div>
          <p>{explanation1}</p>
          <img src={arrow} alt="šipka" />
        </div>
        <div className="productExplanation">
          <div className="productExplanationNumber">2</div>
          <p>{explanation2}</p>
          <img src={arrow} alt="šipka" />
        </div>
        <div className="productExplanation">
          <div className="productExplanationNumber">3</div>
          <p>{explanation3}</p>
          {explanation4 && <img src={arrow} alt="šipka" />}{' '}
        </div>
        {explanation4 && (
          <div className="productExplanation">
            <div className="productExplanationNumber">4</div>
            <p>{explanation4}</p>
          </div>
        )}
      </div>
    </section>
  );
}

export default ProductExplanation;
