import React from 'react';
import Header from '../components/headerComponents/Header';
import Footer from '../components/Footer';
import { useLanguage } from '../context/LanguageContext';
import ProductHero from '../components/productPageComponents/ProductHero';
import simon from '../assets/simon.png';
import simonCheck from '../assets/check_simon.svg';
import ProductExplanation from '../components/productPageComponents/ProductExplanation';
import simonArrow from '../assets/arrow_simon.svg';
import SimonAdvantages from '../components/simonPageComponents/SimonAdvantages';
import SimonPrice from '../components/simonPageComponents/SimonPrice';
import simonLogo from '../assets/simon_logo.png';
import '../styles/simon.scss';
import '../styles/products.scss';

function Simon({ onLanguageChange }) {
  const { dictionary } = useLanguage();

  return (
    <>
      <Header
        claimClass="claimHidden"
        logoClass="productLogo"
        logoSrc={simonLogo}
        onLanguageChange={onLanguageChange}
      />
      <ProductHero
        productName="Simon"
        productTitle={dictionary.simon.title}
        productSubtitle={dictionary.simon.subtitle}
        sellingPoints={[
          dictionary.simon.sellingPoints.li1,
          dictionary.simon.sellingPoints.li2,
          dictionary.simon.sellingPoints.li3,
          dictionary.simon.sellingPoints.li4,
          dictionary.simon.sellingPoints.li5,
          dictionary.simon.sellingPoints.li6,
          dictionary.simon.sellingPoints.li7,
        ]}
        productImage={simon}
        productCheck={simonCheck}
        smallerHeroImg="smallerHeroImg"
      />
      <ProductExplanation
        explanation1={dictionary.simon.explanation.p1}
        explanation2={dictionary.simon.explanation.p2}
        explanation3={dictionary.simon.explanation.p3}
        explanation4={dictionary.simon.explanation.p4}
        arrow={simonArrow}
      />
      <SimonAdvantages />
      <SimonPrice />
      <Footer />
    </>
  );
}

export default Simon;
