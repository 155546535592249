import React from 'react';

const advantages = {
  onesubtitle: 'Everything in your control',
  oneli1: 'customer payments go directly to your account not ours',
  oneli2: 'the customer does not leave your e-shop',
  oneli3: 'you can offer bundles and special deals',
  /*oneli4:"quick and friendly support via e-mail and chat", */
  twosubtitle: 'Advanced features',
  twoli1: 'all e-book formats and MP3 supported',
  twoli2: 'automatically checks the validity of your ePUB',
  twoli3:
    'automatic conversion of your ePUB file to industry standard MOBI and customizable PDF (screen size and formatting)',
  threesubtitle: 'Protection and security',
  threeli1: 'every book is watermarked by state-of-art algorithms',
  threeli2: 'readers are warned not to share the book',
  threeli3: 'removing of watermarks is nearly impossible',
  foursubtitle: 'Real business impact',
  fourli1: 'new instant revenue stream',
  fourli2:
    'does not effect traditional sales channels of e-books and audiobooks',
  fourli3:
    '20-40% higher profit margins compared to traditional sales channels',
  fivesubtitle: 'We will help you',
  fiveli1: 'basic training is provided by our specialist',
  fiveli2: 'quick and friendly support via e-mail and chat',
  fiveli3: 'user-friendly admin and no technical knowledge required',
  fiveli4: 'step-by-step installation guide',
};

function MiguelPrestaAdvantages({ platform }) {
  return (
    <section className="mainSection mp-advantages-section" id="advantages">
      <h2>
        Save thousands of euros with our {platform === 'PrestaShop' ? 'add-on' : 'plugin'} instead of redesigning your
        e-shop
      </h2>
      <div className="advantagesWrapper">
        <div className="advantages mp-advantages">
          <h3>{advantages.foursubtitle}</h3>
          <ul>
            <li>{advantages.fourli1}</li>
            <li>{advantages.fourli2}</li>
            <li>{advantages.fourli3}</li>
          </ul>
        </div>
        <div className="advantages mp-advantages">
          <h3>{advantages.onesubtitle}</h3>
          <ul>
            <li>{advantages.oneli1}</li>
            <li>{advantages.oneli2}</li>
            <li>{advantages.oneli3}</li>
          </ul>
        </div>
        <div className="advantages mp-advantages">
          <h3>{advantages.twosubtitle}</h3>
          <ul>
            <li>{advantages.twoli1}</li>
            <li>{advantages.twoli2}</li>
            <li>{advantages.twoli3}</li>
          </ul>
        </div>
        <div className="advantages mp-advantages">
          <h3>{advantages.threesubtitle}</h3>
          <ul>
            <li>{advantages.threeli1}</li>
            <li>{advantages.threeli2}</li>
            <li>{advantages.threeli3}</li>
          </ul>
        </div>
        <div className="advantages mp-advantages">
          <h3>{advantages.fivesubtitle}</h3>
          <ul>
            <li>{advantages.fiveli1}</li>
            <li>{advantages.fiveli2}</li>
            <li>{advantages.fiveli3}</li>
            <li>{advantages.fiveli4}</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default MiguelPrestaAdvantages;
