import React from 'react';
import { Link } from 'react-router-dom';
import phone from '../../assets/footerPhone.svg';
import email from '../../assets/email.svg';
import { generatePath } from 'react-router-dom';
import en from '../../utils/languages/english.json';
import '../../styles/footer.scss';

function Footer({ czechInvestVisible }) {
  return (
    <footer className="footer">
      <div className="footerColumn">
        <h4>{en.footer.address}</h4>
        <span>Neklanova 150/38</span>
        <span>Vyšehrad</span>
        <span>128 00 Praha</span>
        <span>Czech Republic (EU)</span>
      </div>
      <div className="footerColumn">
        <h4>{en.footer.products}</h4>
        <Link to={generatePath('/:lang/miguel', { lang: 'en' })}>Miguel</Link>
        <Link to={generatePath('/:lang/columbo', { lang: 'en' })}>Columbo</Link>
        <Link to={generatePath('/:lang/simon', { lang: 'en' })}>Simon</Link>
        <Link to={generatePath('/:lang/jacob', { lang: 'en' })}>Jacob</Link>
        <Link to={generatePath('/:lang/edita', { lang: 'en' })}>Edita</Link>
        <Link to={generatePath('/:lang/miluska', { lang: 'en' })}>Miluška</Link>
      </div>
      <div className="footerColumn">
        <h4>{en.footer.information}</h4>
        <Link to={generatePath('/:lang/news', { lang: 'en' })}>
          {en.footer.news}
        </Link>
        <Link to={generatePath('/:lang/reference', { lang: 'en' })}>
          {en.navigation.references}
        </Link>
        <Link to={generatePath('/:lang/about', { lang: 'en' })}>
          {en.navigation.about}
        </Link>
        <Link to={generatePath('/:lang/contact', { lang: 'en' })}>
          {en.navigation.contact}
        </Link>
      </div>
      <div className="footerColumn">
        <h4>{en.footer.contact}</h4>
        <div className="footerContact">
          <img src={phone} alt="ikona telefonu" />
          <span>{en.footer.contactPhone}</span>
        </div>
        <div className="footerContact">
          <img src={email} alt="ikona emailu" />
          <a href="mailto:info@servantes.cz">{en.footer.contactEmail}</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
