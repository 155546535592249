import React, { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';

function RoadmapItem({
  productClaim,
  productLogo,
  productName,
  borderColor,
  order,
  textAlign,
  gridRow,
  gridColumn,
  productUrl,
  productClassName,
  avatar,
}) {
  const navigate = useNavigate();
  const { userLang } = useLanguage();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={{
        gridRow: `${gridRow}`,
        gridColumn: `${gridColumn}`,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => navigate(generatePath(productUrl, { lang: userLang }))}
      className={
        productClassName === 'centeredProduct'
          ? 'centeredProduct roadmapProduct'
          : 'roadmapProduct'
      }
    >
      <div
        style={{
          borderColor: `${borderColor}`,
        }}
        className={
          order === 'orderSecond'
            ? 'orderSecond roadmapProductAvatar'
            : 'roadmapProductAvatar'
        }
      >
        <img
          src={avatar}
          alt="avatar produktu"
          className={`imageTransition ${isHovered ? 'hovered' : ''}`}
        />
        {isHovered ? (
          <div
            className={`roadmapProductLogo ${isHovered ? 'show' : ''}`}
            style={{
              borderColor: `${borderColor}`,
            }}
          >
            <img src={productLogo} alt="logo produktu" />
          </div>
        ) : null}
      </div>

      <div
        className={
          textAlign === 'right'
            ? 'right roadmapProductInfo'
            : 'roadmapProductInfo'
        }
      >
        <h3 style={{ color: `${borderColor}` }}>{productName}</h3>
        <p>{productClaim}</p>
      </div>
    </div>
  );
}

export default RoadmapItem;
