import React from 'react';
import Header from '../components/headerComponents/Header';
import Footer from '../components/Footer';

function Cookies() {
  return (
    <>
      <Header />
      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/60c10353-f37a-4d4b-860e-649f1ec2dbff/cd.js"
        type="text/javascript"
        async
      ></script>
      <Footer />
    </>
  );
}

export default Cookies;
