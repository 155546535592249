import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import miguelScreenshot from '../../assets/miguelScreenshot.png';

function MiguelQuote() {
  const { dictionary } = useLanguage();

  return (
    <section className="mainSection">
      <div className="miguelQuote">
        <img src={miguelScreenshot} alt="" />
        <blockquote>
          <h3>{dictionary.miguel.quote.title}</h3>
          <p>{dictionary.miguel.quote.p1}</p>
          <p>{dictionary.miguel.quote.p2}</p>
          <cite>{dictionary.miguel.quote.cite}</cite>
        </blockquote>
      </div>
    </section>
  );
}

export default MiguelQuote;
