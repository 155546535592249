import React from 'react';

function MiguelPrestaPricing({ pluginURL, platform }) {
  return (
    <section className="mainSection mp-price">
      <h2>Price</h2>
      <div className="priceTable mp-pricetable">
        <div className="miguelTableBody priceTableBody">
          <p>One time installation </p>
          <h3>
            EUR <span>0</span>
          </h3>
          {platform === 'PrestaShop' && (
            <span className="mp-pricing-note">
              (Free installation valid for direct download from this page; price
              on PrestaShop Addons Marketplace is EUR 49)
            </span>
          )}
          <p>Commission</p>
          <h3>10% of sales only</h3>
          <a className="button" href={pluginURL}>
            Download {platform === 'PrestaShop' ? 'add-on' : 'plugin'}
          </a>
        </div>
      </div>
    </section>
  );
}

export default MiguelPrestaPricing;
