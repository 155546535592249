import React from 'react';
import Header from '../components/headerComponents/Header';
import Footer from '../components/Footer';
import { useLanguage } from '../context/LanguageContext';
import editaLogo from '../assets/edita_logo.png';
import edita from '../assets/edita.svg';
import ProductHero from '../components/productPageComponents/ProductHero';
import ProductExplanation from '../components/productPageComponents/ProductExplanation';
import Help from '../components/productPageComponents/Help';
import FAQ from '../components/productPageComponents/FAQ';
import ProductCustomers from '../components/productPageComponents/ProductCustomers';
import melvil from '../assets/publishers/melvil.png';
import mediaplex from '../assets/publishers/mediaplex.png';
import ediCheck from '../assets/check_edita.svg';
import ediArrow from '../assets/arrow_edita.svg';
import EditaPrice from '../components/editaComponents/EditaPrice';
import EditaPreview from '../components/editaComponents/EditaPreview';
import '../styles/edita.scss';
import '../styles/products.scss';

function Edita({ onLanguageChange }) {
  const { dictionary } = useLanguage();

  return (
    <>
      <Header
        claimClass="claimHidden"
        logoSrc={editaLogo}
        logoClass="productLogo"
        onLanguageChange={onLanguageChange}
      />
      <ProductHero
        productName="Edita"
        productTitle={dictionary.edita.title}
        sellingPoints={[
          dictionary.edita.sellingPoints.li1,
          dictionary.edita.sellingPoints.li2,
          dictionary.edita.sellingPoints.li3,
          dictionary.edita.sellingPoints.li4,
          dictionary.edita.sellingPoints.li5,
        ]}
        productImage={edita}
        productCheck={ediCheck}
      />
      <ProductExplanation
        explanation1={dictionary.edita.explanation.p1}
        explanation2={dictionary.edita.explanation.p2}
        explanation3={dictionary.edita.explanation.p3}
        arrow={ediArrow}
      />
      <EditaPreview />
      <ProductCustomers customers={[melvil, mediaplex]} />
      <EditaPrice />
      <Help />
      <FAQ
        faqQuestion1={dictionary.edita.faq.question1}
        faqAnswer1={dictionary.edita.faq.answer1}
        faqQuestion2={dictionary.edita.faq.question2}
        faqAnswer2={dictionary.edita.faq.answer2}
        faqQuestion3={dictionary.edita.faq.question3}
        faqAnswer3={dictionary.edita.faq.answer3}
        faqQuestion4={dictionary.edita.faq.question4}
        faqAnswer4={dictionary.edita.faq.answer4}
        faqQuestion5={dictionary.edita.faq.question5}
        faqAnswer5={dictionary.edita.faq.answer5}
      />
      <Footer />
    </>
  );
}

export default Edita;
