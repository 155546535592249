import React from 'react';
import editaPreview from '../../assets/editaPreview.png';

function EditaPreview() {
  return (
    <section className="mainSection">
      <img src={editaPreview} alt="screenshot z aplikace" />
    </section>
  );
}

export default EditaPreview;
