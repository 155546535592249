import React from 'react';
import Header from '../components/headerComponents/Header';
import Footer from '../components/Footer';
import { useLanguage } from '../context/LanguageContext';
import servantesLogo from '../assets/logo.png';
import Team from '../components/aboutUsComponents/Team';
import teamPhoto from '../assets/team/servantesTeam.webp';
import '../styles/pages.scss';

function AboutUs({ onLanguageChange }) {
  const { dictionary } = useLanguage();

  return (
    <>
      <Header
        claimClass="claimVisible"
        logoSrc={servantesLogo}
        logoClass="servantesLogo"
        onLanguageChange={onLanguageChange}
      />
      <section className="pageSection mainSection">
        <h2>{dictionary.aboutUs.title}</h2>
        <p>{dictionary.aboutUs.p1}</p>
        <p>{dictionary.aboutUs.p2}</p>
        <p>{dictionary.aboutUs.p3}</p>
        <div className="teamPhotoWrapper">
          <img src={teamPhoto} alt="Servantes team" />
        </div>
      </section>
      <Team />
      <Footer />
    </>
  );
}

export default AboutUs;
