import React from 'react';
import PublisherLogo from '../PublisherLogo';

function MiguelPrestaCustomers({ customers, customerUrl }) {
  const customerData = customers.map((customer) => {
    const [customerName, customerUrl] = customer;

    return { customer: customerName, customerUrl };
  });

  return (
    <section className="mainSection mp-customers-section" id="references">
      <h2>Satisfied customers of Miguel and other Servantes products</h2>{' '}
      <div className="customerWrapper">
        {customerData.map((customer, index) => (
          <a
            href={customer.customerUrl}
            key={index}
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            <PublisherLogo
              publisherLogo={customer.customer}
              key={customer.customer}
            />
          </a>
        ))}
      </div>
    </section>
  );
}

export default MiguelPrestaCustomers;
