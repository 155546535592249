import React from 'react';
import Header from '../components/headerComponents/Header';
import Footer from '../components/Footer';
import JacobAdvantages from '../components/jacobPageComponents/JacobAdvantages';
import jacobLogo from '../assets/jacob_logo.webp';
import ProductHero from '../components/productPageComponents/ProductHero';
import JacobPrice from '../components/jacobPageComponents/JacobPrice';
import jacob from '../assets/jacob.svg';
import jacobCheck from '../assets/check_jacob.svg';
import { useLanguage } from '../context/LanguageContext';
import JacobApps from '../components/jacobPageComponents/JacobApps';
import '../styles/jacob.scss';
import '../styles/products.scss';

function Jacob({ onLanguageChange }) {
  const { dictionary, userLang } = useLanguage();

  return (
    <>
      <Header
        claimClass="claimHidden"
        logoClass="productLogo"
        logoSrc={jacobLogo}
        onLanguageChange={onLanguageChange}
      />
      <ProductHero
        productName="Jacob"
        productTitle={dictionary.jacob.title}
        productSubtitle={dictionary.jacob.subtitle}
        productSubtitle2={dictionary.jacob.subtitle2}
        productSubtitle3={dictionary.jacob.subtitle3}
        sellingPoints={[
          dictionary.jacob.sellingPoints.li1,
          dictionary.jacob.sellingPoints.li2,
          dictionary.jacob.sellingPoints.li3,
          dictionary.jacob.sellingPoints.li4,
          dictionary.jacob.sellingPoints.li5,
          dictionary.jacob.sellingPoints.li6,
        ]}
        productImage={jacob}
        productCheck={jacobCheck}
      />
      {userLang === 'cs' ? <JacobApps /> : null}
      <JacobAdvantages />
      <JacobPrice />
      <Footer />
    </>
  );
}

export default Jacob;
