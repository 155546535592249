import React from 'react';
import Header from '../components/headerComponents/Header';
import Footer from '../components/Footer';
import EllaAdvantages from '../components/ellaComponents/EllaAdvantages';
import ellaLogo from '../assets/ella_logo.webp';
import ProductHero from '../components/productPageComponents/ProductHero';
import ella from '../assets/Serv_Ella_recolor_v2_Ella.png';
import ellaCheck from '../assets/check_ella.svg';
import { useLanguage } from '../context/LanguageContext';
import Help from '../components/productPageComponents/Help';
import ellaExample from '../assets/ella-screenshot.webp';
import '../styles/ella.scss';
import '../styles/products.scss';

function Ella({ onLanguageChange }) {
  const { dictionary } = useLanguage();

  return (
    <>
      <Header
        claimClass="claimHidden"
        logoClass="productLogo"
        logoSrc={ellaLogo}
        onLanguageChange={onLanguageChange}
      />
      <ProductHero
        productName="Ella"
        productTitle={dictionary.ella.title}
        productSubtitle={dictionary.ella.subtitle}
        productSubtitle2={dictionary.ella.subtitle2}
        productSubtitle3={dictionary.ella.subtitle3}
        sellingPoints={[
          dictionary.ella.sellingPoints.li1,
          dictionary.ella.sellingPoints.li2,
          dictionary.ella.sellingPoints.li3,
          dictionary.ella.sellingPoints.li4,
          dictionary.ella.sellingPoints.li5,
          dictionary.ella.sellingPoints.li6,
          dictionary.ella.sellingPoints.li7,
        ]}
        productImage={ella}
        productCheck={ellaCheck}
      />
      <EllaAdvantages />
      <section className="mainSection">
        <h2>{dictionary.ella.example}</h2>
        <div
          href="https://melvil.cz/aplikace-melvil"
          target="_blank"
          className="ellaExample"
        >
          <img src={ellaExample} alt="screenshot aplikace" />
        </div>
      </section>
      <Help />
      <Footer />
    </>
  );
}

export default Ella;
