import React from 'react';
import Header from '../components/headerComponents/Header';
import Footer from '../components/Footer';
import { useLanguage } from '../context/LanguageContext';
import columboLogo from '../assets/columbo_logo.png';
import ProductHero from '../components/productPageComponents/ProductHero';
import columbo from '../assets/columbo.svg';
import host from '../assets/publishers/host.png';
import fantom from '../assets/publishers/fantom.png';
import melvil from '../assets/publishers/melvil.png';
import tympanum from '../assets/publishers/tympanum.png';
import oneHot from '../assets/publishers/onehotbook.png';
import paseka from '../assets/publishers/paseka.png';
import supraphon from '../assets/publishers/supraphon.png';
import bourdon from '../assets/publishers/bourdon.png';
import publixing from '../assets/publishers/publixing.png';
import leda from '../assets/publishers/leda.svg';
import euromedia from '../assets/publishers/euromedia.jpeg';
import albatros from '../assets/publishers/albatrosmedia.svg';
import audioteka from '../assets/publishers/audioteka.png';
import ProductCustomers from '../components/productPageComponents/ProductCustomers';
import ColumboAdvantages from '../components/columboComponents/ColumboAdvantages';
import ColumboControlls from '../components/columboComponents/ColumboControlls';
import ColumboForm from '../components/columboComponents/ColumboForm';
import ColumboPrice from '../components/columboComponents/ColumboPrice';
import FAQ from '../components/productPageComponents/FAQ';
import colCheck from '../assets/check_columbo.svg';
import grada from '../assets/publishers/Grada.png';
import ProductExplanation from '../components/productPageComponents/ProductExplanation';
import colArrow from '../assets/arrow_columbo.svg';
import '../styles/columbo.scss';
import '../styles/products.scss';

function Columbo({ onLanguageChange }) {
  const { dictionary } = useLanguage();

  return (
    <>
      <Header
        claimClass="claimHidden"
        logoSrc={columboLogo}
        logoClass="productLogo"
        servantesLogoVisibility="logoVisible"
        onLanguageChange={onLanguageChange}
      />
      <ProductHero
        productName="Columbo"
        productTitle={dictionary.columbo.title}
        sellingPoints={[
          dictionary.columbo.sellingPoints.li1,
          dictionary.columbo.sellingPoints.li2,
          dictionary.columbo.sellingPoints.li3,
          dictionary.columbo.sellingPoints.li4,
          dictionary.columbo.sellingPoints.li5,
          dictionary.columbo.sellingPoints.li6,
        ]}
        productImage={columbo}
        productCheck={colCheck}
      />
      <ProductExplanation
        explanation1={dictionary.columbo.explanation.p1}
        explanation2={dictionary.columbo.explanation.p2}
        explanation3={dictionary.columbo.explanation.p3}
        explanation4={dictionary.columbo.explanation.p4}
        arrow={colArrow}
      />
      <ProductCustomers
        customers={[
          host,
          fantom,
          melvil,
          tympanum,
          oneHot,
          paseka,
          supraphon,
          grada,
          bourdon,
          publixing,
          leda,
          albatros,
          audioteka,
          euromedia,
        ]}
      />
      <ColumboAdvantages />
      <ColumboControlls />
      <ColumboForm />
      <ColumboPrice />
      <FAQ
        faqQuestion1={dictionary.columbo.faq.question1}
        faqAnswer1={dictionary.columbo.faq.answer1}
        faqQuestion2={dictionary.columbo.faq.question2}
        faqAnswer2={dictionary.columbo.faq.answer2}
        faqQuestion3={dictionary.columbo.faq.question3}
        faqAnswer3={dictionary.columbo.faq.answer3}
        faqQuestion4={dictionary.columbo.faq.question4}
        faqAnswer4={dictionary.columbo.faq.answer4}
      />
      <Footer />
    </>
  );
}

export default Columbo;
