import React from 'react';
import { useLanguage } from '../../context/LanguageContext';

function JacobAdvantages() {
  const { dictionary } = useLanguage();

  return (
    <section className="mainSection">
      <h2>{dictionary.columbo.advantagesTitle}</h2>
      <div className="advantagesWrapper">
        <div className="advantages">
          <h3>{dictionary.jacob.advantages.onesubtitle}</h3>
          <ul>
            <li>{dictionary.jacob.advantages.oneli1}</li>
          </ul>
        </div>
        <div className="advantages">
          <h3>{dictionary.jacob.advantages.twosubtitle}</h3>
          <ul>
            <li>{dictionary.jacob.advantages.twoli1}</li>
          </ul>
        </div>
        <div className="advantages">
          <h3>{dictionary.jacob.advantages.threesubtitle}</h3>
          <ul>
            <li>{dictionary.jacob.advantages.threeli1}</li>
          </ul>
        </div>
        <div className="advantages">
          <h3>{dictionary.jacob.advantages.foursubtitle}</h3>
          <ul>
            <li>{dictionary.jacob.advantages.fourli1}</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default JacobAdvantages;
