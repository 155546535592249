import React, { useState, useEffect, useCallback, useRef } from 'react';
import napojeni from '../../assets/napojeni.webp';
import nahrani from '../../assets/nahraniknihy.webp';
import instalace from '../../assets/intalacepluginu.webp';
import pridani from '../../assets/pridani.webp';
import instalaceWoo from '../../assets/instalace-woo.webp';
import usage from '../../assets/usage.webp';

function MiguelPrestaGuide({ platform }) {
  const [activeNavItem, setActiveNavItem] = useState(0);
  const intervalId = useRef(null);

  // Define common nav items
  const commonNavItems = [
    { image: instalace, label: 'Installation', time: '2 minutes' },
    {
      image: nahrani,
      label: 'Uploading of e-books and audiobooks',
      time: '5 minutes',
    },
    {
      image: napojeni,
      label: 'Connection to warehouse and CRM',
      time: '3 minutes',
    },
    {
      image: pridani,
      label: 'Adding to the assortment of products',
      time: '2 minutes',
    },
  ];
  const navItemsWoo = [
    { image: instalaceWoo, label: 'Installation', time: '2 minutes' },
    {
      image: nahrani,
      label: 'Uploading of e-books and audiobooks',
      time: '5 minutes',
    },
    {
      image: usage,
      label: 'Connection to warehouse and CRM',
      time: '3 minutes',
    },
  ];
  // Define platform-specific nav items
  const navItems = platform === 'WooCommerce' ? navItemsWoo : commonNavItems;

  const handleNavItemClick = useCallback((index) => {
    setActiveNavItem(index);
  }, []);

  const resetAutoplay = useCallback(() => {
    if (intervalId.current) clearInterval(intervalId.current);

    const nextNavItem = (activeNavItem + 1) % navItems.length;
    intervalId.current = setInterval(
      () => handleNavItemClick(nextNavItem),
      5000,
    );
  }, [activeNavItem, handleNavItemClick, navItems.length]);

  useEffect(() => {
    resetAutoplay();

    return () => {
      if (intervalId.current) clearInterval(intervalId.current);
    };
  }, [activeNavItem, resetAutoplay]);

  return (
    <section className="mainSection mp-guide" id="setup">
      <h2>Easy and fast setup</h2>
      <div className="mp-guide-main">
        <div className="mp-guide-nav">
          {navItems.map((item, index) => (
            <div
              key={index}
              className={`mp-guide-navItem ${
                activeNavItem === index ? 'active' : ''
              }`}
              onClick={() => handleNavItemClick(index)}
            >
              <span>
                <strong>{item.label}</strong>
              </span>
              <span>{item.time}</span>
            </div>
          ))}
        </div>
        <div className="mp-guide-img">
          <img
            src={navItems[activeNavItem].image}
            alt="screenshot z aplikace"
          />
        </div>
      </div>
    </section>
  );
}

export default MiguelPrestaGuide;
