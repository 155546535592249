import React from 'react';
import Header from '../components/headerComponents/Header';
import Footer from '../components/Footer';
import servantesLogo from '../assets/logo.png';
import tchaiwan1 from '../assets/tchaiwan1.webp';
import tchaiwan2 from '../assets/tchaiwan2.webp';
import '../styles/pages.scss';

function Tchaiwan({ onLanguageChange }) {
  return (
    <>
      <Header
        claimClass="claimVisible"
        logoSrc={servantesLogo}
        logoClass="servantesLogo"
        onLanguageChange={onLanguageChange}
      />
      <section className="pageSection mainSection">
        <h1>
          Brněnská firma Servantes zanechala výraznou stopu na Tchaj-wanu v
          rámci Dumpling Hack
        </h1>
        <figure className="tchaiwanFigure">
          <img
            src={tchaiwan1}
            alt="Václav Soukup prezentující produkty a vize Servantesu na Tchaj-wanu."
          />
          <figcaption>
            Václav Soukup prezentující produkty a vize Servantesu na Tchaj-wanu.
          </figcaption>
        </figure>
        <p>
          <strong>
            Brněnská firma Servantes, která se zaměřuje na vývoj online nástrojů
            pro nakladatele, vyrazila s českou výpravou na Tchaj-wan. V rámci
            akce se účastnila mimo jiné "ideathonu" Dumpling Hack, kde zvítězila
            se svým projektem.
          </strong>
        </p>
        <p>
          Servantes byl jednou z několika firem, které se připojily k české
          výpravě na Tchaj-wan vedené předsedkyní Poslanecké sněmovny Markétou
          Pekarovou Adamovou. Mezi pořádané akce patřil také 36hodinový
          „ideathon“ pořádaný Česko-tchajwanskou podnikatelskou komorou a
          Startup Kitchen,
        </p>
        <p>
          Právě jeho vítězem se pak stal česko-tchajwanský tým Business Copilot,
          jehož zástupcem byl i vedoucí vývoje v Servantesu Václav Soukup. Jeho
          tým se zaměřil na problematiku škálování na nové trhy a rozhodl se
          vytvořit propojovací platformu poháněnou umělou inteligencí.
        </p>
        <figure className="tchaiwanFigure">
          <img
            src={tchaiwan2}
            alt="Vítězný tým Business Copilot s Václavem Soukupem (s oceněním) a ředitelem agentury CzechInvest Petrem očkem (druhý zprava)."
          />
          <figcaption>
            Vítězný tým Business Copilot s Václavem Soukupem (s oceněním) a
            ředitelem agentury CzechInvest Petrem očkem (druhý zprava).
          </figcaption>
        </figure>
        <blockquote className="tchaiwanBlockquote">
          <em>
            "Nemyslel jsem si, že za tak krátkou dobu je možné vymyslet rozumný
            produkt. Ale povedlo se to, a protože sami hledáme klienty pro naše
            nakladatelské řešení na Thaj-wanu, považuji spolupráci s místními
            inovátory a podnikateli za průlomovou,"
          </em>{' '}
          shrnuje své působení Václav Soukup, který mimo jiné dostal prostor i
          na hlavní startup stagi SmartCity Expo.
        </blockquote>
        <p>
          Servantes je dceřinou společností firem BookCore a nakladatelství Jan
          Melvil Publishing. Mezi vyvíjená řešení Servantesu patří např.
          unikátní software pro prodej zabezpečených e-knih a audioknih či
          anti-pirátský program Columbo, chránící autorská díla již více než
          třiceti nakladatelským značkám. Mimo jiné i na platformách Shoptet,
          WooCommerce či Prestashop.
        </p>
        <p>
          Mezi nejnovější počiny pak patří vývoj programu "Simon" na
          automatickou výrobu e-knih, který má za cíl snížit časové a cenové
          náklady na výrobu až o 70 %. Tento projekt zaujal i komisi
          Czechinvestu. Právě Simon tak získal i dotační podporu a stal se
          součástí technologické inkubace, jejíž prostřednictvím Czechinvest
          podporuje nové projekty.
        </p>
        <p>
          Cesta na Tchaj-wan i následný vstup na zahraniční knižní trhy se tak
          pro Servantes stává dalším logickým krokem.
        </p>
      </section>
      <Footer />
    </>
  );
}

export default Tchaiwan;
